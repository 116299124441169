import { Link, useParams } from "react-router-dom";

import { Menu } from "antd";
import { useState } from "react";

const VendorMenu = () => {
  const { subPath } = useParams<{ subPath: string }>();
  const [menuItem, setMenuItem] = useState(subPath);

  const handleClick = (e: any) => setMenuItem(e.key);

  return (
    <Menu onClick={handleClick} selectedKeys={[menuItem]} mode="horizontal">
      <Menu.Item key="my-bookings">
        <Link to="my-bookings">My Bookings</Link>
      </Menu.Item>
      <Menu.Item key="my-summary">
        <Link to="my-summary">My Summary</Link>
      </Menu.Item>
      <Menu.Item key="my-payouts">
        <Link to="my-payouts">My Payouts</Link>
      </Menu.Item>
      <Menu.Item key="my-reviews">
        <Link to="my-reviews">My Reviews</Link>
      </Menu.Item>
    </Menu>
  );
};
export default VendorMenu;
