import { Button, Form, Input, Select } from "antd";

import CustomPageHeader from "../../components/pageHeader/PageHeader";
import Loader from "../../loader/Loader";
import { useEffect } from "react";
import useGetCountryCodes from "../services/useGetCountryCodes";
import useGetUserById from "../services/useGetUserById";
import useUpdateUser from "../services/useUpdateUser";

const AddUser = () => {
  const { loading, handleFinish } = useUpdateUser();
  const { countryCodes, loading: countryCodesLoading } = useGetCountryCodes();
  const { user, loading: userLoading } = useGetUserById();
  //   set form values antd
  const [form] = Form.useForm();
  //   set fields
  useEffect(() => {
    if (!userLoading) {
      form.setFieldsValue({
        name: user?.name,
        email: user?.email,
        mobile: user?.mobile,
        country_code: user?.country_code,
      });
    }
  }, [user, userLoading, form]);
  if (countryCodesLoading && userLoading) return <Loader />;
  return (
    <>
      <CustomPageHeader title="Add or update user" />

      <Form form={form} onFinish={handleFinish} size="large" layout="vertical">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-6">
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
              label="Name"
            >
              <Input placeholder="Name" type={"text"} />
            </Form.Item>
          </div>
          <div className="col-span-6">
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                placeholder="Email"
                type={"email"}
              />
            </Form.Item>
          </div>
          <div className="col-span-6">
            <Form.Item
              rules={[
                { required: false, message: "Please input your country code!" },
              ]}
              label="Country Code"
              name={"country_code"}
            >
              <Select
                placeholder="Select Country Code"
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
              >
                {countryCodes.map((countryCode) => (
                  <Select.Option
                    key={countryCode.dial_code}
                    value={countryCode.dial_code}
                  >
                    {countryCode.dial_code} - {countryCode.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-6">
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: false,
                  message: "Please input your mobile number!",
                },
              ]}
              label="Phone Number"
            >
              <Input placeholder="Phone Number" type="number" />
            </Form.Item>
          </div>
          <div className="col-span-6">
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              htmlType="submit"
              className="w-full"
              block
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddUser;
