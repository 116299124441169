import { CheckCircleTwoTone, StarOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { kebabCase } from "lodash";
import { FC } from "react";
import { useAuth } from "../../services/AuthProvider";
import { IVendor } from "../../vendor/types";

const VendorCard: FC<{ vendor: IVendor }> = ({ vendor }) => {
  const { logout } = useAuth();
  return (
    <Card className="mb-3">
      <div className="flex justify-between">
        <div className="flex items-center">
          <img
            src={vendor?.logo}
            alt={vendor?.name}
            className="h-auto w-20 rounded"
          />
          <div className="px-2" />
          <div>
            <p className="text-2xl mb-1 flex items-center">
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <div className="px-1" />
              <span>{vendor?.name}</span>
            </p>

            <div className="flex items-center">
              <p className="mb-0 flex items-center">
                {vendor?.rating} <StarOutlined />
              </p>
              <div className="px-2">|</div>

              <p className="mb-0">
                <a
                  className="flex items-center"
                  target="blank"
                  href={`https://www.bwtexperiences.com/vendor/${
                    vendor.id
                  }/${kebabCase(vendor.name)}`}
                >
                  <span>Vist my experiences</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div onClick={logout} className="cursor-pointer font-bold">
          Logout
        </div>
      </div>
    </Card>
  );
};
export default VendorCard;
