import { memo } from "react";
import OdinsonEmailEditor from "../../components/emailEditor";
import { CommunicationType } from "../types";

const CommunicationTemplate = ({
  type,
  onSave,
  showConfirm,
}: {
  type: CommunicationType;
  onSave: (html: string) => void;
  showConfirm?: boolean;
}) => {
  const mapTemplate: any = {
    EMAIL: <OdinsonEmailEditor showSaveButton={!showConfirm} onSave={onSave} />,
  };
  return mapTemplate[type] ?? <></>;
};
const MemoizedCommunicationTemplate = memo(CommunicationTemplate);
export default MemoizedCommunicationTemplate;
