import CustomTable, { TD } from "../components/odinsonTable";

import { Link } from "react-router-dom";
import Loader from "../loader/Loader";
import { OfferingsRoot } from "./types";
import { PageHeader } from "antd";
import { getByQueryParam } from "../services/apiService";
import { useQuery } from "react-query";

const ListOffering = () => {
  const params = {
    page: 1,
    limit: 10,
  };
  const { data, isLoading } = useQuery<OfferingsRoot, Error>(
    ["offerings", params.page],
    async () => {
      const response = await getByQueryParam("v1/offerings/", params);
      return response.data;
    }
  );
  if (isLoading) return <Loader />;
  return (
    <div>
      <PageHeader title="Curated Offering List" />

      <CustomTable
        data={data?.data as any[]}
        filterKeys={["tags", "images", "description"]}
      >
        {data?.data.map((item) => (
          <tr>
            <TD>
              <Link to={`/edit-offering/${item.id}`}>{item.id}</Link>
            </TD>
            <TD>{item.title} </TD>
            <TD>{item.short_title}</TD>
            <TD>{item.price}</TD>
            <TD>{item.url}</TD>
          </tr>
        ))}
      </CustomTable>
    </div>
  );
};

export default ListOffering;
