import { useCallback, useEffect, useState } from "react";

import { get } from "../apiService";
import { message } from "antd";

type GetListReturnType = {
  state: any;
  error: boolean;
  loader: boolean;
};

const useGetVendors = (link: string): GetListReturnType => {
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  const getList = useCallback(async () => {
    try {
      setLoader(true);
      const { data } = await get(link);
      setState(data);
    } catch (err) {
      setError(true);
      message.error("Something went wrong!");
    }
    setLoader(false);
  }, [link]);

  useEffect(() => {
    getList();
  }, [getList]);

  return {
    state,
    error,
    loader,
  };
};
export default useGetVendors;
