export enum UserRoles {
  VENDOR = "VENDOR",
  NON_ADMIN = "NON_ADMIN",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum Source {
  MOBILE = "MOBILE",
  SOCIAL = "SOCIAL",
  SYSTEM = "SYSTEM",
}

export enum Platform {
  WEB = "WEB",
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export interface User {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  email: string;
  mobile: string;
  country_code: string;
  referral_code: string;
  profile_pic: string;
  social_uid: string;
  mobile_uid: string;
  is_mobile_verified: boolean;
  is_email_verified: boolean;
  source: Source;
  platform: Platform;
  is_first_login: boolean;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  device_id: string | null;
  fcm_tokens: string[];
  build_version: string;
  role: UserRoles;
  vendor?: {
    name: string;
    id: number;
  };
}
