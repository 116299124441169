import { Button, Modal, Select, message } from "antd";

import axios from "axios";
import { useState } from "react";
import { get } from "../../services/apiService";

type OrderActionProps = {
  item: {
    display_booking_id: string;
    status: string;
  };
};

const OrderAction = ({ item }: OrderActionProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(item.status);

  const updateBookingStatus = async () => {
    try {
      const { data } = await get(`v1/users/me/`);
      if (data.role !== "SUPER_ADMIN" && data.id !== 6) {
        message.error("You are not authorised to perform this action");
        return;
      }
      const UPDATE_URL = process.env.REACT_APP_API_GATEWAY_URL;
      if (!UPDATE_URL) {
        message.error("API Gateway URL not found");
        return;
      }
      const response = await axios.patch(
        UPDATE_URL,
        {
          bookingId: item.display_booking_id,
          status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_GATEWAY_SECRET_KEY,
          },
        }
      );
      if (response.data.statusCode === 200) {
        message.success(response.data.body.message);
      } else {
        message.error(response.data.body.message);
      }
      setModalVisible(false);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <Button
        type="ghost"
        children="Take action"
        size="small"
        onClick={() => setModalVisible(true)}
      />
      <Modal
        title="Important actions"
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <>
            <Button
              type="ghost"
              children="Cancel"
              onClick={() => setModalVisible(false)}
            />
            <Button
              type="primary"
              onClick={updateBookingStatus}
              children="Update"
            />
          </>
        }
      >
        <p className="mb-4">
          Update order status for{" "}
          <span className="font-medium italic underline text-red-400">
            {item?.display_booking_id}
          </span>
          &nbsp;?
        </p>
        <Select
          placeholder="Select order status"
          onChange={(value) => setStatus(value)}
          options={[
            {
              value: "SUCCESS",
              label: "SUCCESS",
            },
            {
              value: "CANCELLED",
              label: "CANCELLED",
            },
            {
              value: "PROCESSING",
              label: "PROCESSING",
            },
          ]}
          defaultValue={item.status}
          style={{ width: "100%" }}
        />
      </Modal>
    </>
  );
};

export default OrderAction;
