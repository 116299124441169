import "./App.css";

import { QueryClient, QueryClientProvider } from "react-query";

import AuthProvider from "./services/AuthProvider";
import Main from "./main/Main";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
