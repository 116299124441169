import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { FC, useState } from "react";
import Logo from "../components/logo/Logo";
import { post } from "../services/apiService";

export type OnLogin = (token: string) => void;

const Login: FC<{ login: OnLogin }> = ({ login }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      const {
        data: { token },
      } = await post("v1/users/me/", values);
      login(token);
    } catch (err) {
      message.error("Something went wrong");
    }

    setLoading(false);
  };

  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-center items-center bg-gray-50">
        <div className="flex items-center mb-8">
          <Logo invert />
        </div>

        <Form
          name="authenticate"
          onFinish={onFinish}
          className="login-form w-1/5"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
                type: "email",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              loading={loading}
              className="login-form-button w-full"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Login;
