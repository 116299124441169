import CustomTable, { TD } from "../../components/odinsonTable";
import { DateFormat, formatDate } from "../../utils/formatDate";

import { FC } from "react";
import { IUser } from "../types";
import { Link } from "react-router-dom";
import { Tag } from "antd";

type UserTableProps = {
  data: IUser[];
};
const FILTER_KEYS = [
  "is_active",
  "is_deleted",
  "updated",
  "extras",
  "url",
  "profile_pic",
  "social_uid",
  "mobile_uid",
  "searchable_text",
  "notifications",
  "device_id",
  "fcm_tokens",
  "build_version",
  "is_first_login",
];

const UserTable: FC<UserTableProps> = ({ data }) => {
  return (
    <CustomTable filterKeys={FILTER_KEYS} data={data}>
      {data.map((user: IUser) => {
        return (
          <tr key={user.id}>
            <TD>
              <Link className="text-blue-800" to={`/edit-user/${user.id}`}>
                {user.id ?? "--"}
              </Link>
            </TD>
            <TD>
              {formatDate(user.created, DateFormat.clientDateFormat, true)}
            </TD>
            <TD>{user.name ?? "--"}</TD>
            <TD>{user.email ?? "--"}</TD>
            <TD>{user.mobile ?? "--"}</TD>
            <TD>{user.country_code ?? "--"}</TD>
            <TD>{user.referral_code ?? "--"}</TD>
            <TD>
              <Tag color={user.is_mobile_verified ? "green" : "red"}>
                {user.is_mobile_verified ? "Yes" : "No"}
              </Tag>
            </TD>
            <TD>
              <Tag color={user.is_email_verified ? "green" : "red"}>
                {user.is_email_verified ? "Yes" : "No"}
              </Tag>
            </TD>
            <TD>{user.source ?? "--"}</TD>
            <TD>{user.platform ?? "--"}</TD>
            <TD>{user.utm_source ?? "--"}</TD>
            <TD>{user.utm_medium ?? "--"}</TD>
            <TD>{user.utm_campaign ?? "--"}</TD>
          </tr>
        );
      })}
    </CustomTable>
  );
};
export default UserTable;
