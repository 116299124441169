import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import { FC } from "react";
const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const { RangePicker } = DatePicker;

const DateRangePicker: FC<{
  // TODO fix any
  defaultValue?: any;
  handleChange: (values: any, formatString: [string, string]) => void;
  disabledData?: any;
}> = ({ handleChange, defaultValue, disabledData }) => (
  <RangePicker
    disabledDate={disabledData}
    placeholder={["Start date", "End date"]}
    defaultValue={defaultValue}
    style={{ width: "100%" }}
    onChange={handleChange}
  />
);
export default DateRangePicker;
