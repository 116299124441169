import ActionHeader from "../../components/ActionHeader";
import CustomPageHeader from "../../components/pageHeader/PageHeader";
import CustomPagination from "../../components/pagination";
import ErrorCard from "../../components/ErrorCard";
import Loader from "../../loader/Loader";
import UserSearchAndFilters from "./userSearchAndFilter";
import UserTable from "./userTable";
import useGetList from "../../services/hooks/useGetList";

const ListUser = () => {
  const { state, page, loader, error } = useGetList("v1/users/");
  if (error) return <ErrorCard />;

  const { total_count, data } = state;

  return (
    <div>
      <CustomPageHeader title="User list" subTitle="All user listing for BWT" />
      <ActionHeader
        page={page}
        totalCount={total_count}
        actionLink="/add-user"
        actionText="Add User"
      />
      <UserSearchAndFilters />
      {loader ? <Loader /> : <UserTable data={data} />}
      <CustomPagination
        total={total_count}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </div>
  );
};
export default ListUser;
