import { FC } from "react";
import ActionHeader from "../components/ActionHeader";
import ErrorCard from "../components/ErrorCard";
import CustomPageHeader from "../components/pageHeader/PageHeader";
import CustomPagination from "../components/pagination";
import Loader from "../loader/Loader";
import useGetList from "../services/hooks/useGetList";
import BlogTable from "./BlogTable";

const ListBlog: FC = () => {
  const { state, loader, error, page } = useGetList("v1/blogs/");

  if (loader) return <Loader />;
  if (error) return <ErrorCard />;

  const { total_count, data } = state;
  return (
    <>
      <CustomPageHeader title="List Blog" subTitle="All blog listing for BWT" />
      <ActionHeader
        page={page}
        totalCount={total_count}
        actionLink="/add-blog"
        actionText="Add Blog"
      />
      <BlogTable data={data} />
      <CustomPagination
        total={total_count}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </>
  );
};

export default ListBlog;
