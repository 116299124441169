import ActionHeader from "../../components/ActionHeader";
import CustomPageHeader from "../../components/pageHeader/PageHeader";
import CustomPagination from "../../components/pagination";
import ErrorCard from "../../components/ErrorCard";
import Loader from "../../loader/Loader";
import VendorTable from "./vendorTable";
import useGetList from "../../services/hooks/useGetList";
const TOTAL_COUNT = 45;
const ListVendor = () => {
  const { state, page, loader, error } = useGetList("v1/vendors/");
  if (error) return <ErrorCard />;

  // const { total_count, data } = state;

  return (
    <div>
      <CustomPageHeader
        title="Vendor list"
        subTitle="All vendor listing for BWT"
      />
      <ActionHeader
        page={page}
        totalCount={TOTAL_COUNT}
        actionLink="/add-vendor"
        actionText="Add Vendor"
      />
      {loader ? <Loader /> : <VendorTable data={state} />}
      <CustomPagination
        total={TOTAL_COUNT}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </div>
  );
};
export default ListVendor;
