import { message } from 'antd'
import querystring from 'querystring'
import { BookingStatus, Currency } from '../types/types'

export const returnQueryParams = (location: any) => querystring.parse(location.search.replace('?', ''))

export const arrayCheck = (array: any): Array<any> => {
    if (Array.isArray(array) && array?.length) return array
    return []
}

export const getCurrencySymbol = (currency: string) => {
    if (!currency) return Currency.INR
    switch (currency.toUpperCase()) {
        case 'INR':
            return Currency.INR
        case 'USD':
            return Currency.USD
        case 'EUR':
            return Currency.EUR
        default:
            return Currency.INR
    }
}

export const getBookingStatusColor = (status: BookingStatus) => {
    switch (status) {
        case BookingStatus.SUCCESS:
            return 'green'
        case BookingStatus.CANCELLED:
            return 'red'
        case BookingStatus.FAILURE:
            return 'orange'
        case BookingStatus.INITIATED:
            return 'cyan'
        case BookingStatus.PROCESSING:
            return 'blue'
        default:
            return 'grey'
    }
}

export const copyToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Copied to clipboard!')
};