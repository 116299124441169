import { Card } from "antd";
const instructionsList = [
  "Make sure the CSV format is correct and it consists of name, email as header.",
  "Make sure we do not have any typos as this is the direct communication to our users.",
  "Make sure the designs of the templates are approved by your peers and manager.",
  "Do not user this without Admin authorization.",
  "Update Subject, CSV file and Save template and continue sending email.",
];
const Instructions = () => {
  return (
    <Card>
      {instructionsList.map((ins, i) => (
        <p>
          <code>
            {i + 1}. {ins}
          </code>
        </p>
      ))}
    </Card>
  );
};
export default Instructions;
