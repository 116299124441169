import CustomTable, { TD } from "../../components/odinsonTable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Currency } from "../../types/types";
import { DateFormat } from "../../utils/formatDate";
import Loader from "../../loader/Loader";
import OdinsonNumberFormat from "../../components/numberFormat";
import { VendorParamsType } from "../types/types";
import dayjs from "dayjs";
import { get } from "../../services/apiService";
import { getCurrencySymbol } from "../../utils/Utils";
import { message } from "antd";
import { useParams } from "react-router-dom";

interface IPayoutItem {
  package: {
    id: number;
    name: string;
    instructor_name: string;
  };
  vendor_payout_amount: number;
}

type GroupListType = {
  package: IPayoutItem["package"];
  total_orders: number;
  total_amount: number;
};

const VendorPayoutDetails = () => {
  const [loader, setLoader] = useState(true);
  const { id } = useParams<VendorParamsType>();
  const [payouts, setPayouts] = useState<GroupListType[]>([]);
  const dateRef = useRef<number>(0);

  const computePayout = useCallback(async (data: IPayoutItem[]) => {
    // groupList orders by package id
    const groupList: GroupListType[] = [];
    data.forEach((item, i) => {
      const index = groupList.findIndex(
        (group: GroupListType) => group.package.id === item.package.id
      );
      // count how many times package.id appears in the list data
      const count = data.reduce((acc, curr) => {
        return curr.package.id === item.package.id ? acc + 1 : acc;
      }, 0);

      if (index === -1) {
        groupList.push({
          package: item.package,
          total_orders: count,
          total_amount: item.vendor_payout_amount,
        });
      } else {
        groupList[index].total_amount += parseInt(
          item.vendor_payout_amount.toFixed(2)
        );
      }
    });

    setPayouts(groupList);
  }, []);

  // get payout list by payout id
  const getPayoutDetailsByVendorId = useCallback(
    async (payoutId: string) => {
      try {
        const { data } = await get(
          `v1/vendors/${id}/payouts/${payoutId}/orders/?page=1`
        );
        await computePayout(data.data);
      } catch (error) {
        message.error((error as Error).message);
      }
    },
    [id, computePayout]
  );
  // get latest payout list
  const getPayoutsByVendorId = useCallback(async () => {
    try {
      const { data } = await get(`v1/vendors/${id}/payouts/?page=1&limit=1`);
      dateRef.current = data.data[0].created;
      await getPayoutDetailsByVendorId(data.data[0].payout_id);
      setLoader(false);
    } catch (error) {
      message.error((error as Error).message);
    }
  }, [id, getPayoutDetailsByVendorId]);

  useEffect(() => {
    getPayoutsByVendorId();
  }, [getPayoutsByVendorId]);

  const totalOrders = useMemo(
    () => payouts.reduce((acc, curr) => acc + curr.total_orders, 0),
    [payouts]
  );
  const totalAmount = useMemo(
    () => payouts.reduce((acc, curr) => acc + curr.total_amount, 0),
    [payouts]
  );
  if (loader) return <Loader />;

  return (
    <div>
      <h4 className="mb-4 font-bold">
        Summary for payout done on{" "}
        {dayjs.unix(dateRef.current).format(DateFormat.clientDateFormat)}
      </h4>
      <CustomTable data={payouts}>
        {payouts.map((payout) => {
          return (
            <tr key={payout.package.id}>
              <TD>
                {payout.package.name}
                <br />
                <span className="text-sm font-medium">
                  (Instructor -
                  {payout.package.instructor_name ?? "Not available"})
                </span>
              </TD>
              <TD>{payout.total_orders}</TD>
              <TD>
                <OdinsonNumberFormat
                  currency={getCurrencySymbol(Currency.INR)}
                  value={payout.total_amount}
                />
              </TD>
            </tr>
          );
        })}
        <tr className="font-bold">
          <TD>Total</TD>
          <TD>{totalOrders}</TD>
          <TD>
            <span className="text-sm font-medium">
              <OdinsonNumberFormat
                currency={getCurrencySymbol(Currency.INR)}
                value={totalAmount}
              />
            </span>
          </TD>
        </tr>
      </CustomTable>{" "}
    </div>
  );
};

export default VendorPayoutDetails;
