import Axios from "axios";
import querystring from "querystring";
import { unauthorise } from "./authService";

export const getBaseUrl = (): string => {
  if (process.env.NODE_ENV === "development")
    return "https://api-dev.bwtexperiences.com";
  return "https://api.bwtexperiences.com";
};

const api = Axios.create({
  baseURL: `${getBaseUrl()}/api/`,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const handleResponse = (res: any) => res;

const handleError = (err: any) => {
  if (err?.response?.status === 401) {
    unauthorise();
    window.location.reload();
    return Promise.resolve({});
  }
  return Promise.reject(err);
};

api.interceptors.response.use(handleResponse, handleError);

export async function verifyToken(token: string): Promise<boolean> {
  try {
    await api.get("", { headers: { Authorization: token } });
    return true;
  } catch (e) {
    return false;
  }
}

export async function get(endPoint: string, headers?: any) {
  return await api.get(endPoint, { headers });
}

export async function getByQueryParam(
  endPoint: string,
  payload: querystring.ParsedUrlQueryInput,
  headers?: any
) {
  return await api.get(`${endPoint}?${querystring.stringify(payload)}`, {
    headers,
  });
}
export async function getByQueryParamTyped<T>(
  endPoint: string,
  payload: querystring.ParsedUrlQueryInput,
  headers?: any
) {
  return await api.get<T>(`${endPoint}?${querystring.stringify(payload)}`, {
    headers,
  });
}

export async function getById(
  endPoint: string,
  id: number | string,
  headers?: any
) {
  return await api.get(`${endPoint}/${id}/`, { headers });
}
export async function getByIdTyped<T>(
  endPoint: string,
  id: number | string,
  headers?: any
) {
  return await api.get<T>(`${endPoint}/${id}/`, { headers });
}

export async function post(endPoint: string, payload: any, headers?: any) {
  return await api.post(endPoint, payload, { headers });
}

export async function put(endPoint: string, payload: any, headers?: any) {
  return await api.put(endPoint, payload, { headers });
}

export async function patch(endPoint: string, payload: any, headers?: any) {
  return await api.patch(endPoint, payload, { headers });
}

export async function patchWithId(
  endPoint: string,
  id: string,
  payload: any,
  headers?: any
) {
  return await api.patch(`${endPoint}/${id}/`, payload, { headers });
}

export async function deleteById(
  endPoint: string,
  id: string | number,
  headers?: any
) {
  return await api.delete(`${endPoint}${id}/`, { headers });
}

export async function deleteWithoutId(
  endPoint: string,
  payload: any,
  headers?: any
) {
  return await api({
    method: "delete",
    url: endPoint,
    data: payload,
    headers: headers,
  });
}

export default api;
