import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { debounce } from "lodash";
import querystring from "querystring";
import { ChangeEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import { returnQueryParams } from "../../utils/Utils";

const UserSearchAndFilters = () => {
  const history = useHistory();
  const queryParams = returnQueryParams(history.location);
  const [q, setQuery] = useState(queryParams.q);
  const pushIntoUrl = (params: any) => {
    const urlParams = {
      ...queryParams,
      ...params,
    };
    history.replace(`?${querystring.stringify(urlParams)}`);
  };

  const debounceUrl = debounce((value) => {
    pushIntoUrl({ q: value, page: 1 });
  }, 1000);
  const onSearchChange = (e: ChangeEventHandler<HTMLInputElement> | any) => {
    const { value } = e.target as { value: string };
    setQuery(value);
    debounceUrl(value);
  };

  return (
    <Input
      className="my-4"
      onChange={onSearchChange}
      onPressEnter={onSearchChange}
      size="large"
      value={q}
      placeholder="Search across names, email, mobile"
      prefix={<SearchOutlined />}
    />
  );
};
export default UserSearchAndFilters;
