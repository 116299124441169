import { message } from "antd";
import { useCallback, useState } from "react";
import { post } from "../../services/apiService";
const usePostTemplate = () => {
  const [loader, setLoader] = useState(false);
  const postTemplate = useCallback(async (payload) => {
    try {
      await post("v1/campaigns/", payload);
      message.success("Template added successfully");
    } catch (e: any) {
      message.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }, []);
  return { postTemplate, loader };
};
export default usePostTemplate;
