import {
  Button,
  Card,
  Form,
  Input,
  PageHeader,
  Select,
  Spin,
  message,
} from "antd";
import { camelCase, debounce, startCase } from "lodash";
import {
  getByIdTyped,
  getByQueryParamTyped,
  patchWithId,
  post,
} from "../services/apiService";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import Editor from "../editor/Editor";
import { ImagesRoot } from "../baskets/types";
import { Offering } from "./types";
import { useParams } from "react-router-dom";

type Payload = {
  title: string;
  short_title: string;
  price: number;
  keywords: string;
  image_ids: number[];
  tag_ids: number[];
  url: string;
  description: string;
};

type OfferingPayload = {
  title: string;
  short_title: string;
  price: number;
  keywords: string;
  description: string;
  url: string;
  image_ids: {
    id: number;
  }[];
  tag_ids: {
    id: number;
  }[];
};
type SearchParams = {
  page: number;
  limit: number;
  use_pagination_v2: boolean;
  q: string;
};

const AddOffering = () => {
  const [form] = Form.useForm();
  const path = useParams() as { id: string };
  const [params, setParams] = useState<SearchParams>({
    page: 1,
    limit: 10,
    use_pagination_v2: true,
    q: "",
  });
  const { isLoading, mutate } = useMutation(
    (payload: OfferingPayload) => {
      if (path.id) {
        return patchWithId(`v1/offerings`, path.id, payload);
      }
      return post(`v1/offerings/`, payload);
    },
    {
      onSuccess: () => {
        message.success("Offering added successfully!");
      },
      onError: () => {
        message.error("Error adding offering!");
      },
    }
  );
  const { data: offering } = useQuery<Offering, Error>(
    "basketItem",
    async () => {
      const { data } = await getByIdTyped<Offering>("v1/offerings", path.id);
      return data;
    },
    {
      enabled: !!path.id,
    }
  );
  useEffect(() => {
    if (offering && path.id) {
      form.setFieldsValue({
        title: offering.title,
        short_title: offering.short_title,
        price: offering.price,
        keywords: offering.keywords,
        url: offering.url,
        image_ids: offering.images.map((image) => image.id),
        description: offering.description,
      });
    }
  }, [form, offering, path.id]);
  const { data, isLoading: isImageLoading } = useQuery(
    ["images", params],
    async () =>
      await getByQueryParamTyped<ImagesRoot>("/v1/uploads/images/", params),
    {
      enabled: params.q.length > 0,
    }
  );
  const onFinish = (values: Payload) => {
    const payload = {
      ...values,
      image_ids: values?.image_ids?.map((id) => ({ id })),
      tag_ids: values?.tag_ids?.map((id) => ({ id })),
    };
    mutate(payload);
  };
  return (
    <Card>
      <PageHeader title={`${path.id ? "Edit" : "Add"} offering`} />
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="grid grid-cols-2 gap-8"
      >
        {["title", "short_title", "price", "keywords", "url"].map(
          (formItem) => {
            const normalString = startCase(camelCase(formItem));
            return (
              <Form.Item
                key={formItem}
                label={normalString}
                name={formItem}
                rules={[
                  {
                    required: true,
                    message: `Please input ${formItem}!`,
                  },
                ]}
              >
                <Input placeholder={`Please enter ${normalString}`} />
              </Form.Item>
            );
          }
        )}
        {[{ key: "image_ids" }].map((formItem) => {
          const normalString = startCase(camelCase(formItem.key));
          return (
            <Form.Item
              className="col-span-1"
              key={formItem.key}
              label={normalString}
              name={formItem.key}
              rules={[
                {
                  required: true,
                  message: `Please input ${formItem.key}!`,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                loading={isImageLoading}
                onChange={(value) => {
                  form.setFieldsValue({
                    [formItem.key]: value,
                  });
                }}
                onSearch={debounce((value: string) => {
                  setParams({
                    ...params,
                    q: value,
                  });
                }, 1000)}
                notFoundContent={
                  isImageLoading ? <Spin size="small" /> : "No data found"
                }
              >
                {data?.data.data.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    <div className="flex gap-2">
                      <img
                        src={item.urls.jpeg}
                        alt={item.name}
                        style={{ width: "50px", height: "50px" }}
                      />
                      {item.name}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          );
        })}
        <Form.Item
          className="col-span-2"
          name="description"
          label="Description"
          rules={[{ required: false, message: "Please input body" }]}
        >
          <Editor
            saveData={(data: { [key: string]: any }) =>
              form.setFieldsValue({ description: data })
            }
            initialValue={
              offering?.description || form.getFieldValue("description")
            }
          />
        </Form.Item>
        <Form.Item className="col-span-2">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {path.id ? "Update" : "Create"} Offering
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddOffering;
