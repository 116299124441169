import dayjs from "dayjs";

export enum DateFormat {
  serverDateFormat = "YYYY-MM-DD",
  clientDateFormat = "DD MMM, YYYY",
  timeFormat = "HH:MM A",
}

export const formatDate = (
  date: number | undefined,
  format?: DateFormat,
  unix?: boolean
) => {
  if (unix)
    return date
      ? dayjs.unix(date).format(format ?? DateFormat.clientDateFormat)
      : dayjs().format(format ?? DateFormat.clientDateFormat);
  return dayjs(date).format(format ?? DateFormat.clientDateFormat);
};
