import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { IVendorReviews } from "./../types/types";
import { get } from "../../services/apiService";

type VendorParam = {
  id: string;
};
const LIMIT = 12;
const useGetVendorReviews = () => {
  const location = useLocation();
  const page = location.search.replaceAll("?page=", "") || "1";
  const params = useParams<VendorParam>();
  const [loader, setLoader] = useState(true);
  const [reviews, setReviews] = useState<{
    data: IVendorReviews[];
    total_count: number;
  }>({
    data: [],
    total_count: 0,
  });
  const getReviews = useCallback(async () => {
    try {
      const { data } = await get(
        `v1/vendors/${params.id}/ratings/?page=${page}&limit=${LIMIT}`
      );
      setReviews(data);
    } catch (e) {
      setLoader(false);
    }
    setLoader(false);
  }, [params.id, page]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return {
    loader,
    reviews: reviews.data,
    page,
    totalCount: reviews.total_count,
  };
};

export default useGetVendorReviews;
