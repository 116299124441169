import Layout, { Content } from "antd/lib/layout/layout";
import { Route, Switch, useHistory } from "react-router-dom";

import AddBasket from "../baskets/addBasket";
import AddBlog from "../blog/AddBlog";
import AddNewTemplate from "../template/addNewTemplate";
import AddOffering from "../offerings/addOffering";
import AddOrder from "../orders/AddOrder";
import AddUser from "../user/addUser";
import EditExperiences from "../experiences/editExperiences";
import ListBasket from "../baskets/listBasket";
import ListBlog from "../blog/ListBlog";
import ListExperiences from "../experiences/listExperiences";
import ListOffering from "../offerings/listOffering";
import ListUser from "../user/listUser";
import ListVendor from "../vendor/listVendor";
import { SIDEBAR_MENU } from "../constants/Constants";
import SendEmail from "../communication/sendEmail";
import SideBar from "../sidebar/Sidebar";
import { UserRoles } from "../entities/user";
import VendorManagementSystem from "../vendorManagementSystem";
import { kebabCase } from "lodash";
import { useAuth } from "../services/AuthProvider";
import { useEffect } from "react";

const Main = () => {
  const data = useAuth();
  const history = useHistory();
  const ifUserIsVendor = data.user?.role === UserRoles.VENDOR;
  useEffect(() => {
    if (ifUserIsVendor)
      history.push(
        `/${kebabCase(data.user?.vendor?.name)}/dashboard/${
          data.user?.vendor?.id
        }/my-bookings?page=1`
      );
  }, [
    data.user?.role,
    history,
    ifUserIsVendor,
    data.user?.vendor?.name,
    data.user?.vendor?.id,
  ]);
  return (
    <Layout>
      {!ifUserIsVendor && <SideBar />}
      <Layout
        className="site-layout p-4"
        style={ifUserIsVendor ? {} : { marginLeft: 200 }}
      >
        <Content>
          <Switch>
            <Route exact path="/edit-blog/:blogID" component={AddBlog} />
            <Route exact path="/edit-order/:id" component={AddOrder} />
            <Route exact path="/list-blog" component={ListBlog} />
            <Route exact path="/list-experiences" component={ListExperiences} />
            <Route
              exact
              path="/edit-experiences/:id"
              component={EditExperiences}
            />
            <Route exact path="/add-offering" component={AddOffering} />
            <Route exact path="/edit-offering/:id" component={AddOffering} />
            <Route exact path="/list-offering" component={ListOffering} />
            <Route exact path="/add-basket" component={AddBasket} />
            <Route exact path="/edit-basket/:id" component={AddBasket} />
            <Route exact path="/list-basket" component={ListBasket} />
            <Route exact path="/send-email" component={SendEmail} />
            <Route exact path="/list-vendor" component={ListVendor} />
            <Route exact path="/list-user" component={ListUser} />
            <Route exact path="/add-user" component={AddUser} />
            <Route exact path="/edit-user/:id" component={AddUser} />
            <Route exact path="/add-template" component={AddNewTemplate} />
            <Route
              exact
              path="/:name/dashboard/:id/:subPath"
              component={VendorManagementSystem}
            />

            {SIDEBAR_MENU.map(({ subMenu }) =>
              subMenu.map((sMenu) => (
                <Route
                  key={sMenu.link}
                  exact
                  path={sMenu.link}
                  component={sMenu.component}
                />
              ))
            )}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
