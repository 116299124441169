import { message } from "antd";
import { useCallback, useState } from "react";
import { post } from "../../services/apiService";

type PayloadType = {
  start_date: string | string[] | undefined;
  end_date: string | string[] | undefined;
  exclude_order_ids?: string[];
  experience_id?: string | number | undefined;
  exclude_large_duration_orders: boolean;
};

const useConfirmVendorPayout = (
  vendor_id: string,
  start_date: string | string[] | undefined,
  end_date: string | string[] | undefined,
  exclude_large_duration_orders: boolean,
  exclude_order_ids: string[] = [],
  onSuccess?: Function,
  experience_id?: string | number | undefined
) => {
  const [loader, setLoader] = useState(false);
  const confirmVendorPayout = useCallback(async () => {
    setLoader(true);
    const payload: PayloadType = {
      start_date,
      end_date,
      exclude_large_duration_orders,
    };
    if (exclude_order_ids) payload.exclude_order_ids = exclude_order_ids;
    if (experience_id) payload.experience_id = experience_id;
    try {
      await post(`v1/vendors/${vendor_id}/payouts/confirm/`, payload);
      onSuccess && onSuccess();
      message.success("Payout successfull marked as paid");
    } catch (err: any) {
      message.error("Something went wrong");
    }
    setLoader(false);
  }, [
    exclude_order_ids,
    start_date,
    end_date,
    vendor_id,
    onSuccess,
    experience_id,
    exclude_large_duration_orders,
  ]);

  return {
    confirmVendorPayout,
    loader,
  };
};

export default useConfirmVendorPayout;
