import { useCallback, useState } from "react";

import { ExperiencePayload } from "../types";
import { message } from "antd";
import { patchWithId } from "../../services/apiService";

const useUpdateExperience = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const patchDetails = useCallback(
    async (payload: ExperiencePayload) => {
      setLoading(true);
      try {
        await patchWithId("/v1/packages", id, payload);
        message.success("Experience updated successfully");
      } catch (error) {
        message.error("Something went wrong!");
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [id]
  );
  return {
    loading,
    error,
    patchDetails,
  };
};
export default useUpdateExperience;
