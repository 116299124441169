import { Tag, Tooltip } from "antd";
import CustomTable, { TD } from "../../components/odinsonTable";
import { DATE_FORMAT, TIME_FORMAT } from "../../constants/Constants";
import {
  arrayCheck,
  copyToClipboard,
  getBookingStatusColor,
  getCurrencySymbol,
} from "../../utils/Utils";

import { CopyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { startCase } from "lodash";
import { FC } from "react";
import { Link } from "react-router-dom";
import OdinsonNumberFormat from "../../components/numberFormat";
import ExperienceDetails from "./ExperienceDetails";
import ImportantLinks from "./ImportantLinks";
import OrderAction from "./OrderAction";
import UserDetails from "./UserDetail";

const FILTER_KEYS = [
  "is_active",
  "is_deleted",
  "updated",
  "currency",
  "mode",
  "booking_id",
  "contact_name",
  "contact_mobile",
  "contact_country_code",
  "notification",
  "extras",
  "status",
  "experience_time_slot_id",
  "notifications",
  "searchable_text",
  "rating",
  "can_book_again",
  "remaining_amount",
  "can_calendar",
  "package",
  "user",
  "time_remaining",
  "upcoming_trips",
  "can_rate_booking",
  "is_booking_rated",
  "is_experience_rated",
  "is_calendared",
  "referee_booking_id",
  "offer_code",
  "promo_code",
  "basket_id",
  "order_subscription_plan_id",
  "payment_info",
];

const ADD_KEYS = [
  {
    key: "User",
    position: 2,
  },
  {
    key: "Action",
    position: 3,
  },
  {
    key: "Experience",
    position: 4,
  },
  {
    key: "Important Links",
    position: 5,
  },
  {
    key: "Status",
    position: 6,
  },
  {
    key: "Remaining Amount",
    position: 11,
  },
];

const OrderTable: FC<{ data: Array<any>; vendorView?: boolean }> = ({
  data,
}) => {
  return (
    <CustomTable addKeys={ADD_KEYS} filterKeys={FILTER_KEYS} data={data}>
      {arrayCheck(data).map((item) => (
        <tr key={item.id}>
          <TD>
            <Link to={`/edit-order/${item.booking_id}`}>{item.id}</Link>
          </TD>

          <TD>{dayjs.unix(item.created).format(TIME_FORMAT)}</TD>
          <TD>
            {item.user.name}
            &nbsp;
            <UserDetails user={item.user} />
          </TD>
          <TD>
            <OrderAction item={item} />
          </TD>
          <TD>
            {item?.package?.name}
            &nbsp;
            <ExperienceDetails experience={item.package} />
          </TD>
          <TD>
            <ImportantLinks
              id={item?.booking_id}
              title={item?.package?.name}
              paymentLink={item?.payment_link}
              url={item?.package?.url}
              experienceId={item?.package?.id}
            />
          </TD>
          <TD>
            <Tag color={getBookingStatusColor(item.status)}>{item.status}</Tag>
          </TD>
          <TD>
            <Tooltip color="green" title={item.booking_id}>
              {item.display_booking_id}
            </Tooltip>
          </TD>
          <TD>{item.pax}</TD>

          <TD>
            <OdinsonNumberFormat
              currency={getCurrencySymbol(item.currency)}
              value={item.amount}
            />
          </TD>
          <TD>
            <OdinsonNumberFormat
              currency={getCurrencySymbol(item.currency)}
              value={item.payable_amount}
            />
          </TD>
          <TD>
            <OdinsonNumberFormat
              currency={getCurrencySymbol(item.currency)}
              value={item.remaining_amount}
            />
          </TD>

          <TD>{dayjs.unix(item.start_date).format(DATE_FORMAT)}</TD>
          <TD>{dayjs.unix(item.end_date).format(DATE_FORMAT)}</TD>
          <TD>
            {item.start_time
              ? dayjs.unix(item.start_time).format(TIME_FORMAT)
              : "--"}
          </TD>
          <TD>
            {item.end_time
              ? dayjs.unix(item.end_time).format(TIME_FORMAT)
              : "--"}
          </TD>
          <TD>
            {item.payment_link ? (
              <span
                className="flex items-center cursor-pointer"
                onClick={() => copyToClipboard(item?.payment_link)}
              >
                {item?.payment_link}
                <CopyOutlined />
              </span>
            ) : (
              "--"
            )}
          </TD>
          <TD>
            {item.accommodation_details ? (
              <Tooltip
                color="cyan"
                title={
                  <div className="text-xs p-2">
                    {Object.keys(item.accommodation_details).map((key) => (
                      <p key={key}>
                        <span className="font-extralight">
                          {startCase(key)}: &nbsp;
                        </span>
                        {key === "best_deal" ||
                        key === "is_private" ||
                        key === "is_active"
                          ? key
                            ? "Yes"
                            : "No"
                          : item.accommodation_details[key] ?? "--"}
                      </p>
                    ))}
                  </div>
                }
              >
                <Tag color="cyan">View Detail</Tag>
              </Tooltip>
            ) : (
              "--"
            )}
          </TD>
          <TD>
            {item.subscription_details ? (
              <Tooltip
                color="cyan"
                title={
                  <div className="text-xs p-2">
                    {Object.keys(item.subscription_details).map((key) => (
                      <p key={key}>
                        <span className="font-extralight">
                          {startCase(key)}: &nbsp;
                        </span>
                        {key === "best_deal"
                          ? key
                            ? "Yes"
                            : "No"
                          : item.subscription_details[key]}
                      </p>
                    ))}
                  </div>
                }
              >
                <Tag color="cyan">View Detail</Tag>
              </Tooltip>
            ) : (
              "--"
            )}
          </TD>
        </tr>
      ))}
    </CustomTable>
  );
};

export default OrderTable;
