import { FC } from "react"

interface LogoProps {
    invert?: boolean
}

const Logo: FC<LogoProps> = ({ invert }) => <div className='flex items-center justify-start m-5'>
    <img src="https://cdn.bookweekendtours.com/image-bank/new-bwt-logo_orig.png" alt="BWT" className='w-8' />
    <span className={`text-sm font-medium px-2 ${invert ? 'text-black' : 'text-white'}`}>
        experiences
</span>

</div>
export default Logo