import ActionHeader from "../../components/ActionHeader";
import CustomPagination from "../../components/pagination";
import Loader from "../../loader/Loader";
import VendorReviewsTable from "./vendorReviewsTable";
import useGetVendorReviews from "../services/useGetVendorReviews";

const VendorReviews = () => {
  const { reviews, loader, page, totalCount } = useGetVendorReviews();
  return (
    <div>
      <ActionHeader page={page} totalCount={totalCount} />
      {loader ? <Loader /> : <VendorReviewsTable reviews={reviews} />}
      <CustomPagination
        total={totalCount}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </div>
  );
};

export default VendorReviews;
