import AddBasket from "../baskets/addBasket";
import AddBlog from "../blog/AddBlog";
import AddNewTemplate from "../template/addNewTemplate";
import AddOffering from "../offerings/addOffering";
import AddOrder from "../orders/AddOrder";
import ListBasket from "../baskets/listBasket";
import ListOffering from "../offerings/listOffering";
import ListOrder from "../orders/ListOrders";
import SendEmail from "../communication/sendEmail";
import SendMessage from "../communication/sendMessage";

export const SIDEBAR_MENU = [
  {
    title: "Dashboard",
    subMenu: [
      {
        itemTitle: "Dashboard",
        itemIcon: "tachometer",
        link: "/dashboard",
      },
    ],
  },
  {
    title: "Offerings",
    subMenu: [
      {
        itemTitle: "Add Offering",
        itemIcon: "offering",
        link: "/add-offering",
        component: AddOffering,
      },
      {
        itemTitle: "List Offering",
        itemIcon: "tachometer",
        link: "/list-offering",
        component: ListOffering,
      },
    ],
  },
  {
    title: "Baskets",
    subMenu: [
      {
        itemTitle: "Add Basket",
        itemIcon: "basket",
        link: "/add-basket",
        component: AddBasket,
      },
      {
        itemTitle: "List Basket",
        itemIcon: "tachometer",
        link: "/list-basket",
        component: ListBasket,
      },
    ],
  },
  {
    title: "Communication",
    subMenu: [
      {
        itemTitle: "Send Bulk Email",
        itemIcon: "tachometer",
        link: "/send-email",
        component: SendEmail,
      },
      {
        itemTitle: "Send Bulk Message",
        itemIcon: "tachometer",
        link: "/send-message",
        component: SendMessage,
      },
    ],
  },
  {
    title: "Template",
    subMenu: [
      {
        itemTitle: "Add New Template",
        link: "/add-template",
        component: AddNewTemplate,
      },
    ],
  },
  {
    title: "Users",
    subMenu: [
      {
        itemTitle: "Add User",
        itemIcon: "plus",
        link: "/add-user",
      },
      {
        itemTitle: "List Users",
        itemIcon: "list",
        link: "/list-user",
      },
    ],
  },
  {
    title: "Orders",
    subMenu: [
      {
        itemTitle: "Add Order",
        itemIcon: "plus",
        link: "/add-order",
        component: AddOrder,
      },
      {
        itemTitle: "List Orders",
        itemIcon: "list",
        link: "/list-orders",
        component: ListOrder,
      },
    ],
  },

  {
    title: "Reviews",
    subMenu: [
      {
        itemTitle: "List Reviews",
        itemIcon: "list",
        link: "list-reviews",
      },
    ],
  },
  {
    title: "Tags",

    subMenu: [
      {
        itemTitle: "Tags",
        itemIcon: "tags",
        link: "add-tags",
      },
    ],
  },
  {
    title: "Blog",
    subMenu: [
      {
        itemTitle: "Add Blog",
        itemIcon: "plus",
        link: "/add-blog",
        component: AddBlog,
      },
      {
        itemTitle: "List Blog",
        itemIcon: "list",
        link: "/list-blog",
      },
    ],
  },
  {
    title: "Videos",
    subMenu: [
      {
        itemTitle: "Add Videos",
        itemIcon: "plus",
        link: "add-video",
      },
      {
        itemTitle: "List Videos",
        itemIcon: "list",
        link: "list-video",
      },
    ],
  },
  {
    title: "Experiences",
    subMenu: [
      {
        itemTitle: "Add Experience",
        itemIcon: "plus",
        link: "add-experience",
      },
      {
        itemTitle: "List Experiences",
        itemIcon: "list",
        link: "/list-experiences",
      },
    ],
  },
  {
    title: "Images",
    subMenu: [
      {
        itemTitle: "Add Images",
        itemIcon: "plus",
        link: "add-image",
      },
      {
        itemTitle: "List Images",
        itemIcon: "list",
        link: "list-image",
      },
    ],
  },
  {
    title: "Vendor",
    subMenu: [
      {
        itemTitle: "Add Vendors",
        itemIcon: "plus",
        link: "/add-vendor",
      },
      {
        itemTitle: "List Vendors",
        itemIcon: "list",
        link: "/list-vendor",
      },
    ],
  },
  {
    title: "Location",

    subMenu: [
      {
        itemTitle: "Add Location",
        itemIcon: "plus",
        link: "add-location",
      },
      {
        itemTitle: "List Location",
        itemIcon: "list",
        link: "list-location",
      },
    ],
  },
  {
    title: "Categories",

    subMenu: [
      {
        itemTitle: "Add Categories",
        itemIcon: "plus",
        link: "add-categories",
      },
      {
        itemTitle: "List Categories",
        itemIcon: "list",
        link: "list-categories",
      },
    ],
  },
].sort((a, b) => (a.title !== b.title ? (a.title < b.title ? -1 : 1) : 0));

export const LIMIT = 12;

export const DATE_FORMAT = "DD MMM YYYY";
export const TIME_FORMAT = "DD MMM YYYY | HH:MM A";
