import { FC, memo } from "react"
import { LIMIT } from "../../constants/Constants"

const ShowPageOfTotalPage: FC<{ page: string | string[], totalCount: number, limit: number }> = ({ page, totalCount = 1 }) => {
    return <p className="text-gray-400 mb-0">
        Page <span className='text-2xl text-black'>
            {
                page
            }
        </span> / {
            Math.ceil(totalCount / LIMIT) || 1
        }
    </p>
}
export default memo(ShowPageOfTotalPage)