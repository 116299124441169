import CustomTable, { TD } from "../../components/odinsonTable";
import { DateFormat, formatDate } from "../../utils/formatDate";

import { FC } from "react";
import GoToActionButtonVMS from "./goToVmsActionButton";
import { IVendor } from "../types";
import { Link } from "react-router-dom";

interface VendorTableProps {
  data: IVendor[];
}

const FILTER_KEYS = [
  "is_active",
  "is_deleted",
  "updated",
  "extras",
  "url",
  "about",
];
const ADD_KEYS = [
  {
    key: "Dashboard",
    position: 0,
  },
];
const VendorTable: FC<VendorTableProps> = ({ data }) => (
  <CustomTable filterKeys={FILTER_KEYS} addKeys={ADD_KEYS} data={data}>
    {data.map((vendor: IVendor) => {
      return (
        <tr key={vendor.id}>
          <TD>
            <GoToActionButtonVMS vendor={vendor} />
          </TD>
          <TD>
            <Link to={`/edit-vendor/${vendor.id}`}>{vendor.id}</Link>
          </TD>
          <TD>
            {formatDate(vendor.created, DateFormat.clientDateFormat, true)}
          </TD>
          <TD>{vendor.name}</TD>
          <TD>{vendor.contact_person_name ?? "-"}</TD>
          <TD>{vendor.email ?? "-"}</TD>
          <TD>{vendor.mobile ?? "-"}</TD>
          <TD>{vendor.no_of_packages ?? "-"}</TD>
          <TD>{`${vendor.commission}%` ?? "-"}</TD>
          <TD>{vendor.logo ? "Added" : "Not added"}</TD>
          <TD>{`${vendor.rating} star` ?? "-"}</TD>
          <TD>{`${vendor.advance_pay_percent}%` ?? "-"}</TD>
          <TD>{vendor.days_until_cancellation ?? "-"}</TD>
        </tr>
      );
    })}
  </CustomTable>
);
export default VendorTable;
