import { Button, Form, Input, Select, message } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { get, getById, patch, post } from "../services/apiService";
import { useHistory, useParams } from "react-router-dom";

import AddTag from "./AddTag";
import CustomPageHeader from "../components/pageHeader/PageHeader";
import Editor from "../editor/Editor";
import Loader from "../loader/Loader";
import { arrayCheck } from "../utils/Utils";

const { Option } = Select;

const URL = `v1/blogs`;
const AddBlog: FC = () => {
  const { blogID } = useParams() as { blogID: string };

  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoader, setPageLoader] = useState<boolean>(!!blogID);
  const [data, setData] = useState<any>({});
  const [blog, setBlog] = useState<any>(null);
  const [tags, setTags] = useState<any>(null);
  const [payloadTags, setPayloadTags] = useState<any>([]);

  const history = useHistory();

  const getBlogByID = useCallback(async () => {
    const { data } = await getById(URL, blogID);
    setBlog(data);
    setData(data?.blog_data);
    setPageLoader(false);
  }, [blogID]);

  const update = async (payload: any) => {
    try {
      await patch(`${URL}/${blogID}/`, payload);
      message.success("Updated successfully!");
    } catch (err) {
      message.error("Something went wrong!");
    }
  };

  const create = async (payload: any) => {
    try {
      const res = await post(`${URL}/`, payload);
      history.replace(`/edit-blog/${res.data.blog_id}`);
      message.success("Added successfully!");
    } catch (err) {
      message.error("Something went wrong!");
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      let payload = {
        ...values,
        blog_data: data,
        tags: payloadTags,
      };
      if (blogID) await update(payload);
      else await create(payload);
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (blogID) {
      getBlogByID();
    }
  }, [blogID, getBlogByID]);

  const getTags = async () => {
    const { data } = await get("v1/blogs/tags/");
    setTags(data);
  };
  useEffect(() => {
    getTags();
  }, []);

  const handleTags = (value: Array<string>) => {
    setPayloadTags(value);
  };

  if (pageLoader) return <Loader />;

  return (
    <div className="container">
      <CustomPageHeader
        title={`${blogID ? "Update" : "Add"} Blog`}
        subTitle={blog?.title}
      />
      <Form
        size="large"
        layout="vertical"
        name="addBlog"
        className="grid grid-cols-2 gap-1"
        initialValues={blog}
        onFinish={onFinish}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            { required: true, message: "Please input title!", type: "string" },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="URL"
          name="image"
          rules={[
            { required: true, message: "Please enter url!", type: "string" },
          ]}
        >
          <Input placeholder="URL" />
        </Form.Item>
        <Form.Item
          label="Keywords"
          name="keywords"
          rules={[
            {
              required: true,
              message: "Please enter Keywords!",
              type: "string",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter description!",
              type: "string",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item className="col-span-full" label="Editor">
          <Editor initialValue={blog?.blog_data} saveData={setData} />
        </Form.Item>
        <span>Tags</span>
        <Select
          className="col-span-full"
          disabled={!tags}
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags"
          defaultValue={arrayCheck(blog?.tags).map((tag: any) => tag.id)}
          onChange={handleTags}
          dropdownRender={(menu) => (
            <AddTag menu={menu} tags={tags} updateTags={getTags} />
          )}
        >
          {arrayCheck(tags).map((tag: any) => (
            <Option key={tag?.id} value={tag?.id}>
              {tag?.name}
            </Option>
          ))}
        </Select>
        <Form.Item>
          <Button
            disabled={loading}
            loading={loading}
            className="login-form-button w-1/2"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddBlog;
