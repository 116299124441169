import { useCallback, useEffect, useState } from "react";

import { IUser } from "./../types";
import { getById } from "../../services/apiService";
import { message } from "antd";
import { useParams } from "react-router-dom";

const useGetUserById = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const getUserById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getById(`v1/users`, id);
      setUser(data);
    } catch (error) {
      message.error((error as Error).message);
    }
    setLoading(false);
  }, [id]);
  useEffect(() => {
    if (id) getUserById();
  }, [getUserById, id]);
  return {
    loading,
    user,
    getUserById,
  };
};
export default useGetUserById;
