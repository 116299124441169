import { FC, useEffect, useRef } from "react";
import { get, post } from "../services/apiService";

import EditorJS from "@editorjs/editorjs";
import React from "react";
import { debounce } from "lodash";
import { message } from "antd";

const ImageTool = require("@editorjs/image");
const Header = require("@editorjs/header");
const LinkTool = require("@editorjs/link");
const RawTool = require("@editorjs/raw");
const Embed = require("@editorjs/embed");
const Checklist = require("@editorjs/checklist");
const Quote = require("@editorjs/quote");
const NestedList = require("@editorjs/nested-list");
const Table = require("@editorjs/table");
const InitialConfiguration = {
  holder: "editorjsTwo",
  tools: {
    header: {
      class: Header,
      config: {
        placeholder: "Enter a header",
        levels: [1, 2, 3, 4, 5, 6],
        defaultLevel: 2,
      },
    },
    table: {
      class: Table,
    },
    linkTool: {
      class: LinkTool,
    },
    list: {
      class: NestedList,
      inlineToolbar: true,
    },
    raw: RawTool,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          async uploadByFile(file: any) {
            const uploadData = new FormData();
            uploadData.append("image", file, file.name);
            uploadData.append("name", file.name);
            try {
              const {
                headers: { location = "" },
              } = await post(`v1/uploads/images/`, uploadData, {
                observe: "response",
              });
              if (location) {
                const {
                  data: {
                    urls: { original },
                  },
                } = await get(location);
                return {
                  success: 1,
                  file: {
                    url: `https://img.bwtexperiences.com/unsafe/800x500/filters:format(jpg)/${original}`,
                  },
                };
              }
            } catch (err) {
              message.error("Something went wrong");
            }
          },
        },
      },
    },
    checklist: {
      class: Checklist,
      inlineToolbar: true,
    },
    embed: {
      class: Embed,
      inlineToolbar: true,
      config: {
        services: {
          youtube: true,
          twitter: true,
          instagram: true,
        },
      },
    },
    quote: Quote,
  },
  autofocus: true,
};

type EditorProps = {
  saveData: (d: any) => void;
  initialValue: any;
};
const Editor2: FC<EditorProps> = ({ saveData, initialValue }) => {
  const ejInstance = useRef<EditorJS>();
  const initEditor = () => {
    const editor = new EditorJS({
      ...InitialConfiguration,
      data: initialValue,
      onReady: () => {
        ejInstance.current = editor;
      },
      onChange: debounce(
        (api: any) => api.saver.save().then((o: any) => saveData(o)),
        1500
      ),
      autofocus: true,
      tools: {
        header: Header,
      },
    });
  };
  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      if (ejInstance.current) {
        ejInstance.current.destroy();
        ejInstance.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-3">
      <div className="bg-white border rounded-sm shadow-lg mb-2" id="editorjs">
        <React.Fragment>
          <div id={"editorjsTwo"}> </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default Editor2;
