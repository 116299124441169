import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";
import CustomTable, { TD } from "../components/odinsonTable";
import { message, Switch } from "antd";
import { patchWithId } from "../services/apiService";

const FILTER_KEYS = ['description', 'image', 'updated'];

const BlogTable: FC<{ data: Array<any> }> = ({ data }) => {


    const onSwitchChange = async (is_active: boolean, id: string) => {
        try {
            await patchWithId(`v1/blogs`, id, { is_active })
            message.success(`Publication is ${is_active ? 'On' : 'Off'}`)
        }
        catch (err) {
            message.error('Something went wrong.')
        }
    }

    return <CustomTable filterKeys={FILTER_KEYS} data={data}>
        {data.map((item) => (
            <tr key={item.id}>
                <TD>
                    {item.id}
                </TD>
                <TD>
                    {dayjs.unix(item.created).format('DD MMM YY')}
                </TD>
                <TD>
                    <Link to={`/edit-blog/${item.id}`}>
                        {item.title}
                    </Link>
                </TD>
                <TD>
                    <Switch
                        defaultChecked={item.is_active}
                        onChange={(value) => onSwitchChange(value, item.id)} />
                </TD>

            </tr>
        ))}


    </CustomTable>


}

export default BlogTable;
