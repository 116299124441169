import { Currency } from "../../types/types";
import { FC } from "react";
import NumberFormat from "react-number-format";

type OdinsonNumberFormatProps = {
  value: number | undefined;
  currency: Currency;
};
const OdinsonNumberFormat: FC<OdinsonNumberFormatProps> = ({
  value,
  currency = Currency.INR,
}) => {
  return (
    <NumberFormat
      readOnly
      style={{ background: "transparent" }}
      thousandSeparator
      thousandsGroupStyle={currency === Currency.INR ? "lakh" : "thousand"}
      value={value}
      prefix={currency}
    />
  );
};

export default OdinsonNumberFormat;
