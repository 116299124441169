import { FC, ReactNode } from "react";
import { Select, SelectProps, Spin } from "antd";

import { DebouncedFunc } from "lodash";

type SearchAndSelectProps = {
  fetchOptions: DebouncedFunc<(value: string) => Promise<void>>;
  setValue: (value: any) => void;
  mode?: "multiple" | "tags";
  placeholder: string;
  options: ReactNode;
  fetching: boolean;
  disabled?: boolean;
  props?: SelectProps<any>;
  defaultValue?: { value: string | number | undefined; label: string };
};

const SearchAndSelect: FC<SearchAndSelectProps> = ({
  fetchOptions,
  setValue,
  mode,
  placeholder,
  options,
  fetching,
  props,
  defaultValue,
  disabled,
}) => {
  return (
    <Select
      disabled={disabled}
      defaultValue={defaultValue}
      allowClear
      labelInValue
      filterOption={false}
      mode={mode}
      placeholder={placeholder}
      showSearch
      virtual
      onSearch={fetchOptions}
      notFoundContent={fetching ? <Spin size="small" /> : "No data found"}
      onChange={(newValue: any) => {
        setValue(newValue);
      }}
      style={{ width: "100%" }}
      {...props}
    >
      {options}
    </Select>
  );
};
export default SearchAndSelect;
