import { Button } from "antd";
import TimeRangePicker from "../components/timePicker";
import moment from "moment";
import useGetExperiencesById from "./services/useGetExperienceById";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import useUpdateExperience from "./services/useUpdateExperience";
type TimeRangeProps = {
  defaultValues?: any;
  handleChange: (values: any, formatString: [string, string]) => void;
};
const EditExperiences = () => {
  const params = useParams<{ id: string }>();
  const { experience, loading: experienceLoader } = useGetExperiencesById(
    params.id
  );
  const { patchDetails, loading } = useUpdateExperience(params.id);
  const timeRef = useRef<any>();
  if (experienceLoader) return <div>Loading...</div>;
  const defaultTime =
    experience?.start_time && experience?.end_time
      ? [
          moment(experience?.start_time, "HH:mm:ss"),
          moment(experience?.end_time, "HH:mm:ss"),
        ]
      : null;
  const timeRangeProps: TimeRangeProps = {
    handleChange: (_, formatString) => {
      timeRef.current = formatString;
    },
  };
  if (defaultTime) {
    timeRangeProps.defaultValues = defaultTime;
  }
  return (
    <>
      <p>{experience?.title}</p>
      <TimeRangePicker {...timeRangeProps} />
      <Button
        onClick={() => {
          patchDetails({
            start_time: timeRef.current[0],
            end_time: timeRef.current[1],
          });
        }}
      >
        {loading ? "Loading..." : "Update"}
      </Button>
    </>
  );
};
export default EditExperiences;
