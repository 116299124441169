import { Link } from "react-router-dom";
import Logo from "../components/logo/Logo";
import { Menu } from "antd";
import { SIDEBAR_MENU } from "../constants/Constants";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { useAuth } from "../services/AuthProvider";

const SideBar = () => {
  const { logout } = useAuth();
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>
        <Logo />
        {SIDEBAR_MENU.map((menu) => (
          <SubMenu key={menu.title} title={menu.title}>
            {menu.subMenu.map((s) => (
              <Menu.Item key={s.itemTitle}>
                <Link to={s.link}>{s.itemTitle}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ))}

        <Menu.Item key="logout" onClick={logout}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideBar;
