import { useCallback, useEffect, useState } from "react";

import { ICountryCode } from "../types";
import { get } from "../../services/apiService";
import { message } from "antd";

const useGetCountryCodes = () => {
  const [loading, setLoading] = useState(false);
  const [countryCodes, setCountryCodes] = useState<ICountryCode[]>([]);

  const getCountryCodes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await get("v1/config/country-codes/");
      setCountryCodes(data);
    } catch (error) {
      message.error((error as Error).message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getCountryCodes();
  }, [getCountryCodes]);

  return {
    getCountryCodes,
    countryCodes,
    loading,
  };
};
export default useGetCountryCodes;
