import ActionHeader from "../../components/ActionHeader";
import CustomPagination from "../../components/pagination";
import ErrorCard from "../../components/ErrorCard";
import Loader from "../../loader/Loader";
import { VendorParamsType } from "../types/types";
import VendorPayoutTable from "./vendorPayoutTable";
import useGetList from "../../services/hooks/useGetList";
import { useParams } from "react-router-dom";

const VendorPayouts = () => {
  const { id } = useParams<VendorParamsType>();
  const { state, page, loader, error } = useGetList(
    `v1/vendors/${id}/payouts/`
  );
  if (error) return <ErrorCard />;

  const { total_count, data } = state;

  return (
    <div className="my-2">
      <ActionHeader page={page} totalCount={total_count} />
      {loader ? <Loader /> : <VendorPayoutTable data={data} />}
      <CustomPagination
        total={total_count}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </div>
  );
};
export default VendorPayouts;
