import { useCallback, useState } from "react";

import { getByQueryParamTyped } from "../../services/apiService";
import { message } from "antd";

const useGetBasketValues = <Response>() => {
  const [data, setData] = useState<Response>();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async (url, q) => {
    const params = {
      page: 1,
      limit: 12,
      use_pagination_v2: true,
      q,
    };
    setLoading(true);
    try {
      const { data } = await getByQueryParamTyped<Response>(url, params);
      setData(data);
    } catch (e) {
      message.error("Something went wrong");
    }
    setLoading(false);
  }, []);

  return {
    fetch,
    data,
    loading,
  };
};

export default useGetBasketValues;
