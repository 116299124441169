import { Switch } from "antd";
import { FC } from "react";

const OdinsonSwitch: FC<{
  onChange: () => void;
  defaultChecked?: boolean;
  switchLabel?: string;
}> = ({ onChange, defaultChecked, switchLabel }) => {
  return (
    <Switch
      checkedChildren={switchLabel}
      unCheckedChildren={switchLabel}
      defaultChecked={defaultChecked}
      onChange={onChange}
    />
  );
};
export default OdinsonSwitch;
