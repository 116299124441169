export enum BookingStatus {
    SUCCESS = 'SUCCESS',
    CANCELLED = 'CANCELLED',
    INITIATED = 'INITIATED',
    PROCESSING = 'PROCESSING',
    FAILURE = 'FAILURE'
}

export enum Currency {
    INR = '₹',
    USD = '$',
    EUR = '€'
}
