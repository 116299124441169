import { Button, Form, Input, message } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { getById, patchWithId, post } from "../../services/apiService";
import { DateFormat, formatDate } from "../../utils/formatDate";
import { IUser, SlotType } from "./types";

import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import DateRangePicker from "../../components/DateRangePicker";
import OdinsonSelect from "../../components/odinsonSelect";
import CustomPageHeader from "../../components/pageHeader/PageHeader";
import Loader from "../../loader/Loader";
import SearchExperience from "./searchExperience";
import SearchUser from "./searchUser";

const URL_BOOKINGS = "v1/orders";
const URL_EXPERIENCES = "v1/packages";
type ParamsType = {
  id: string;
};
const AddOrder: FC = () => {
  const { id } = useParams<ParamsType>();
  const [state, setState] = useState({
    start_date: "",
    end_date: "",
    subscription_plan_id: null,
    package_id: null,
  });
  const [selectedExperience, setSelectedExperience] = useState<any>(null);
  const [upcomingTripId, setUpcomingTripId] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const bookingDataRef = useRef<any>(null);
  const setFormData = useCallback(
    (name: string, value: string | number) =>
      setState((state) => ({ ...state, [name]: value })),
    []
  );
  const getExperienceById = useCallback(async (id: string) => {
    if (!id) return;
    const { data } = await getById(URL_EXPERIENCES, id);
    setSelectedExperience(data);
  }, []);

  const getBookingById = useCallback(async () => {
    setLoader(true);
    const { data } = await getById(URL_BOOKINGS, id);
    bookingDataRef.current = data;
    await getExperienceById(data.package.id);
    setUser(data.user);
    setLoader(false);
  }, [id, getExperienceById]);

  useEffect(() => {
    if (id) getBookingById();
  }, [id, getBookingById]);

  const onFinish = () => {
    setBtnLoader(true);
    if (id) {
      const payload = {
        ...state,
      };

      if (!state.start_date && !state.end_date) {
        payload.start_date = formatDate(
          bookingDataRef.current.start_date,
          DateFormat.serverDateFormat,
          true
        );
        payload.end_date = formatDate(
          bookingDataRef.current.end_date,
          DateFormat.serverDateFormat,
          true
        );
      }

      if (!state.package_id) {
        payload.package_id = bookingDataRef.current.package.id;
      }

      const { subscription_plan_id, ...newPayload } = payload;

      patchWithId("v1/orders", id, newPayload)
        .then(() => {
          message.success("Order updated successfully.");
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    } else {
      post("v1/orders/", state)
        .then(() => {
          message.success("Order created successfully.");
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    }

    setBtnLoader(false);
  };

  if (loader) return <Loader />;
  return (
    <>
      <CustomPageHeader title={`${id ? "Update" : "Add"} Order`} />
      <Form
        size="large"
        layout="vertical"
        name="addOrder"
        className="grid grid-cols-2 gap-1"
        initialValues={state}
        onFinish={onFinish}
      >
        <Form.Item
          label="Search User"
          name="user_id"
          rules={[{ message: "Please select user", type: "number" }]}
        >
          <SearchUser user={user} handleSetValue={setFormData} />
        </Form.Item>
        <Form.Item
          label="Search Experience"
          name="package_id"
          rules={[
            {
              message: "Please select experience",
              type: "number",
            },
          ]}
        >
          <SearchExperience
            handleSetValue={setFormData}
            setSelectedExperience={setSelectedExperience}
            selectedExperience={selectedExperience}
          />
        </Form.Item>
        <Form.Item
          label="Select Price"
          name="accommodation_id"
          rules={[
            {
              message: "Please select pricing",
              type: "string",
            },
          ]}
        >
          <OdinsonSelect
            disabled={!selectedExperience || !!bookingDataRef?.current}
            defaultValue={
              bookingDataRef?.current && {
                label: `${bookingDataRef?.current?.accommodation_details?.accommodation_type} | ${bookingDataRef?.current?.accommodation_details?.price}`,
                value: bookingDataRef?.current?.accommodation_details?.id,
              }
            }
            showSearch={false}
            placeholder="Select Pricing"
            handleChange={(value: any) =>
              setFormData("accommodation_id", value.value)
            }
            options={
              selectedExperience
                ? selectedExperience.accommodation_pricing.map((type: any) => ({
                    label: `${type.accommodation_type} | ${type.price}`,
                    value: type.id,
                  }))
                : []
            }
          />
        </Form.Item>
        <Form.Item
          label="Select Subscription"
          name="subscription_plan_id"
          rules={[
            {
              message: "Please select subscription",
              type: "string",
            },
          ]}
        >
          <OdinsonSelect
            disabled={!selectedExperience || !!bookingDataRef?.current}
            defaultValue={
              bookingDataRef?.current && {
                label: `${bookingDataRef?.current?.subscription_details?.name} | ${bookingDataRef?.current?.subscription_details?.discount_price}`,
                value: bookingDataRef?.current?.subscription_details?.id,
              }
            }
            showSearch={false}
            placeholder="Select Subscription"
            handleChange={(value: any) =>
              setFormData("subscription_plan_id", value.value)
            }
            options={
              selectedExperience
                ? selectedExperience.subscription_plans.map(
                    (subscription: any) => ({
                      label: `${subscription.name} | ${subscription.discount_price}`,
                      value: subscription.id,
                    })
                  )
                : []
            }
          />
        </Form.Item>

        <Form.Item
          label="Select Slot"
          name="upcoming_trip_id"
          rules={[
            {
              message: "Please select date slot",
              type: "string",
            },
          ]}
        >
          <OdinsonSelect
            defaultValue={
              bookingDataRef?.current && {
                label: `${formatDate(
                  bookingDataRef?.current?.start_date,
                  DateFormat.clientDateFormat,
                  true
                )} - ${formatDate(
                  bookingDataRef?.current?.end_date,
                  DateFormat.clientDateFormat,
                  true
                )}`,
                value: "NA",
              }
            }
            disabled={
              !selectedExperience?.upcoming_trips.length ||
              !!bookingDataRef?.current
            }
            showSearch={false}
            placeholder="Select date slot"
            handleChange={(value: any) => {
              if (!value) return;
              const selectedDateSlot = selectedExperience.upcoming_trips.find(
                (slot: SlotType) => slot.id === value.value
              );
              setUpcomingTripId(value);
              setState({
                ...state,
                start_date: formatDate(
                  selectedDateSlot.start,
                  DateFormat.serverDateFormat,
                  true
                ),
                end_date: dayjs
                  .unix(selectedDateSlot.end)
                  .add(
                    selectedExperience.subscription_plans.find(
                      (plan: any) => plan.id === state.subscription_plan_id
                    )?.duration - 1 ?? 1,
                    "month"
                  )
                  .format(DateFormat.serverDateFormat),
              });
            }}
            options={
              selectedExperience
                ? selectedExperience.upcoming_trips.map((slot: any) => ({
                    label: `${formatDate(
                      slot.start,
                      DateFormat.clientDateFormat,
                      true
                    )} | ${dayjs
                      .unix(slot.end)
                      .add(
                        selectedExperience.subscription_plans.find(
                          (plan: any) => plan.id === state.subscription_plan_id
                        )?.duration - 1 ?? 1,
                        "month"
                      )
                      .format(DateFormat.clientDateFormat)}`,
                    value: slot.id,
                  }))
                : []
            }
          />
        </Form.Item>
        <Form.Item
          label="Select time slot"
          name="experience_time_slot_id"
          rules={[
            {
              message: "Please select time slot",
              type: "string",
            },
          ]}
        >
          <OdinsonSelect
            disabled={
              !selectedExperience?.upcoming_trips.find(
                (slot: any) => slot.id === upcomingTripId
              )?.time_slots.length
            }
            showSearch={false}
            placeholder="Select time slot"
            handleChange={(value: string) => {
              if (!value) return;
              setFormData("experience_time_slot_id", value);
            }}
            options={
              selectedExperience
                ? selectedExperience?.upcoming_trips
                    .find((slot: any) => slot.id === upcomingTripId)
                    ?.time_slots.map((time: any) => ({
                      label: `${formatDate(
                        time.start_at,
                        DateFormat.timeFormat,
                        true
                      )} | ${formatDate(
                        time.end_at,
                        DateFormat.timeFormat,
                        true
                      )}`,
                      value: time.id,
                    }))
                : []
            }
          />
        </Form.Item>

        <Form.Item
          label="PAX"
          name="pax"
          rules={[
            {
              message: "Please input number of people",
              type: "string",
            },
          ]}
        >
          <Input
            disabled={!!bookingDataRef?.current}
            defaultValue={bookingDataRef?.current?.pax}
            onChange={(e) => setFormData("pax", Number(e.target.value))}
            placeholder="Number of people"
          />
        </Form.Item>
        <Form.Item
          label="Referral Code"
          name="promo_code"
          rules={[
            {
              message: "Please input referral code",
              type: "string",
            },
          ]}
        >
          <Input
            defaultValue={bookingDataRef?.current?.promo_code}
            onChange={(e) => setFormData("promo_code", e.target.value)}
            placeholder="Referral code"
          />
        </Form.Item>
        <Form.Item
          label="Custom date"
          name="custom_date_range"
          rules={[
            {
              required: false,
              message: "Please input number of people",
              type: "string",
            },
          ]}
        >
          <DateRangePicker
            defaultValue={
              bookingDataRef.current
                ? [
                    dayjs.unix(bookingDataRef?.current?.start_date),
                    dayjs.unix(bookingDataRef?.current?.end_date),
                  ]
                : []
            }
            handleChange={(value) => {
              if (!value) return;
              setState({
                ...state,
                start_date: formatDate(value[0], DateFormat.serverDateFormat),
                end_date: formatDate(value[1], DateFormat.serverDateFormat),
              });
            }}
          />
        </Form.Item>

        <Form.Item className="col-span-full">
          <Button
            disabled={btnLoader}
            loading={btnLoader}
            className="login-form-button w-1/4"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddOrder;
