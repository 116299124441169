import CustomTable, { TD } from "../../components/odinsonTable";

import { Currency } from "../../types/types";
import { DateFormat } from "../../utils/formatDate";
import { FC } from "react";
import { IVendorPayout } from "../types/types";
import OdinsonNumberFormat from "../../components/numberFormat";
import dayjs from "dayjs";
import { getCurrencySymbol } from "../../utils/Utils";

interface VendorTableProps {
  data: IVendorPayout[];
}

const FILTER_KEYS = ["is_active", "is_deleted", "updated", "vendor_id"];
const VendorPayoutTable: FC<VendorTableProps> = ({ data }) => {
  return (
    <CustomTable filterKeys={FILTER_KEYS} data={data}>
      {data.map((payout: IVendorPayout) => {
        return (
          <tr key={payout.id}>
            <TD>{payout.id}</TD>
            <TD>
              {dayjs.unix(payout.created).format(DateFormat.clientDateFormat)}
            </TD>
            <TD>{payout.payout_id}</TD>
            <TD>
              <OdinsonNumberFormat
                currency={getCurrencySymbol(Currency.INR)}
                value={payout.amount}
              />
            </TD>
            <TD>{payout?.remarks?.ref_id ?? "--"}</TD>
          </tr>
        );
      })}
    </CustomTable>
  );
};

export default VendorPayoutTable;
