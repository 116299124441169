import { Button } from "antd";
import { Currency } from "../../types/types";
import { FC } from "react";
import { ISummaryData } from "../types/types";
import Loader from "../../loader/Loader";
import OdinsonModal from "../../components/odinsonModal";
import OdinsonNumberFormat from "../../components/numberFormat";

type SummaryModalProps = {
  summaryModal: boolean;
  setSummaryModal: (summaryModal: boolean) => void;
  confirmPayout: () => void;
  summaryData: ISummaryData | undefined;
  loader: boolean;
  confirmPayoutLoader: boolean;
  ifUserIsVendor: boolean;
};

const SummaryModal: FC<SummaryModalProps> = ({
  summaryData,
  summaryModal,
  confirmPayout,
  setSummaryModal,
  loader,
  confirmPayoutLoader,
  ifUserIsVendor,
}) => {
  return (
    <OdinsonModal
      title="Payout confirmation"
      visible={summaryModal}
      handleOk={confirmPayout}
      handleCancel={() => setSummaryModal(false)}
    >
      {loader ? (
        <Loader />
      ) : (
        <div className="text-center">
          <p>Total count: {summaryData?.count}</p>
          <h1>
            <OdinsonNumberFormat
              currency={Currency.INR}
              value={summaryData?.total_payable_amount}
            />
          </h1>
          {!ifUserIsVendor && (
            <div className="flex items-center justify-center">
              <div>
                <Button
                  disabled={confirmPayoutLoader}
                  loading={confirmPayoutLoader}
                  onClick={confirmPayout}
                  type={"primary"}
                  children="Confirm Payout"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </OdinsonModal>
  );
};

export default SummaryModal;
