import { DateFormat, formatDate } from "../../utils/formatDate";
import { FC, useCallback, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useHistory, useParams } from "react-router-dom";

import ActionHeader from "../../components/ActionHeader";
import CustomPagination from "../../components/pagination";
import ErrorCard from "../../components/ErrorCard";
import Loader from "../../loader/Loader";
import { OdinsonOption } from "../../components/odinsonSelect";
import SummaryModal from "./summaryModal";
import VendorBookingActionBar from "./vendorBookingActionBar";
import VendorBookingsTable from "./vendorBookingsTable";
import { VendorParamsType } from "../types/types";
import querystring from "querystring";
import useConfirmVendorPayout from "../services/useConfirmVendorPayout";
import useGeneratePayoutSummary from "../services/useGeneratePayoutSummary";
import useGetList from "../../services/hooks/useGetList";
import { useToggle } from "../../hooks/useToggle";

const defaultDateRange = formatDate(
  undefined,
  DateFormat.serverDateFormat,
  false
);

const VendorBookings: FC<{ start_date: string; ifUserIsVendor: boolean }> = ({
  start_date,
  ifUserIsVendor,
}) => {
  const [showOneMonthData, setShowOneMonthData] = useToggle(true);
  const history = useHistory();
  const { id } = useParams<VendorParamsType>();

  const [summaryModal, setSummaryModal] = useState(false);

  const initialDateRange = useMemo(() => {
    return {
      start_date:
        dayjs(start_date).get("year") >= 2021 ? start_date : "2021-01-01",
      end_date: defaultDateRange,
    };
  }, [start_date]);

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [selectedExperienceID, setSelectedExperienceID] = useState<
    string | number
  >();

  const {
    summary,
    loader: summaryLoader,
    generatePayoutSummary,
  } = useGeneratePayoutSummary(
    id,
    dateRange.start_date,
    dateRange.end_date,
    undefined,
    selectedExperienceID,
    showOneMonthData
  );

  const { state, loader, error, page } = useGetList(
    `v1/vendors/${id}/orders/`,
    {
      ...dateRange,
      exclude_large_duration_orders: showOneMonthData,
      experience_id: selectedExperienceID,
    }
  );

  const {
    state: vendorExperiences,
    loader: vendorExpLoader,
    error: vendorExpErr,
  } = useGetList(`v1/vendors/${id}/packages/`, { customPage: 1 });

  const { confirmVendorPayout, loader: confirmPayoutLoader } =
    useConfirmVendorPayout(
      id,
      dateRange.start_date,
      dateRange.end_date,
      showOneMonthData,
      [],
      () => setSummaryModal(false),
      selectedExperienceID
    );

  const filterDateRange = useCallback(
    (value: Dayjs[]) => {
      const params = { page: 1 };
      if (value) {
        setDateRange({
          start_date: dayjs(value[0]).format(DateFormat.serverDateFormat),
          end_date: dayjs(value[1]).format(DateFormat.serverDateFormat),
        });
      } else {
        setDateRange(initialDateRange);
      }
      history.replace(`?${querystring.stringify(params)}`);
    },
    [initialDateRange, history]
  );

  const openSummaryModal = useCallback(() => {
    setSummaryModal(true);
    generatePayoutSummary();
  }, [generatePayoutSummary]);

  if (error) return <ErrorCard />;

  const getVendorExperiences = () => {
    if (!vendorExpLoader && !vendorExpErr && vendorExperiences.data.length) {
      return vendorExperiences.data.map((exp: any) => ({
        label: exp.short_title,
        value: exp.id,
      }));
    }
  };

  const onExperiencesSelected = (option: OdinsonOption) => {
    const params = { page: 1 };
    if (option) setSelectedExperienceID(option.value);
    else setSelectedExperienceID(undefined);
    history.replace(`?${querystring.stringify(params)}`);
  };
  const { data, total_count } = state;

  return (
    <div className="my-2">
      <SummaryModal
        ifUserIsVendor={ifUserIsVendor}
        summaryModal={summaryModal}
        summaryData={summary}
        loader={summaryLoader}
        confirmPayoutLoader={confirmPayoutLoader}
        setSummaryModal={setSummaryModal}
        confirmPayout={confirmVendorPayout}
      />
      <ActionHeader page={page} totalCount={total_count} />

      <VendorBookingActionBar
        ifUserIsVendor={ifUserIsVendor}
        switchLabel={showOneMonthData ? "One" : "All"}
        handleDurationChange={setShowOneMonthData}
        defaultChecked={showOneMonthData}
        start_date={start_date}
        filterDateRange={filterDateRange}
        generateSummary={openSummaryModal}
        vendorExpOptions={getVendorExperiences()}
        onExperiencesSelected={onExperiencesSelected}
      />
      {loader ? <Loader /> : <VendorBookingsTable data={data} />}
      <CustomPagination
        total={total_count}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </div>
  );
};
export default VendorBookings;
