import { PageHeader } from "antd"
import { FC } from "react"
import { useHistory } from "react-router-dom"

interface CustomPageHeaderProps {
    title: string,
    subTitle?: string
}

const CustomPageHeader: FC<CustomPageHeaderProps> = ({ title, subTitle }) => {
    const history = useHistory()
    return <PageHeader
        style={{
            background: '#FFF',
            borderRadius: '8px',
            marginBottom: '1rem'
        }}
        onBack={history.goBack}
        title={title}
        subTitle={subTitle}
    />
}

export default CustomPageHeader