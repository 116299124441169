import { startCase } from "lodash";
import { arrayCheck } from "../../utils/Utils";

interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  render: JSX.Element;
}

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const useGetTableData = (
  data: Array<any>,
  filterKeys: Array<string>,
  addKeys?: Array<{ key: string; position: number }>
) => {
  const initialObj = arrayCheck(data).length ? data[0] : {};
  const tableHeader = Object.keys(initialObj).filter(
    (it) => !filterKeys.includes(it)
  );
  if (arrayCheck(addKeys)) {
    addKeys?.forEach((key) => {
      tableHeader.splice(key.position, 0, key.key);
    });
  }
  const tableColumns: Array<Columns | any> = tableHeader.map((key) => ({
    title: startCase(key),
  }));

  const tableData: Array<DataType | any> = data.map((cell) => ({
    ...cell,
  }));

  return {
    tableColumns,
    tableData,
  };
};

export default useGetTableData;
