import { Button, Form, Input, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import CustomPageHeader from "../../components/pageHeader/PageHeader";
import usePostTemplate from "../services/usePostTemplate";
import { CommunicationType } from "../types";
import MemoizedCommunicationTemplate from "./communicationTemplate";

export type TemplateFormType = {
  name: string;
  subject: string;
  body: string;
  communication_type: string;
};

const AddNewTemplate = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { postTemplate, loader } = usePostTemplate();
  const [form] = useForm();

  const onFinish = () => postTemplate(formValues);

  const setTemplate = (html: string) => {
    setShowConfirm(true);
    setFormValues({
      ...formValues,
      body: html,
    });
  };

  return (
    <>
      <CustomPageHeader
        title="Add New Template"
        subTitle="Register a new template to broadcast message to customer"
      />
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="communication_type" label="Communication Type">
            <Radio.Group>
              <Radio value={CommunicationType.EMAIL}>
                {CommunicationType.EMAIL}
              </Radio>
              <Radio value={CommunicationType.SMS}>
                {CommunicationType.SMS}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </>

        <MemoizedCommunicationTemplate
          showConfirm={showConfirm}
          type={form.getFieldValue("communication_type")}
          onSave={(html) => {
            setTemplate(html);
          }}
        />

        {showConfirm && (
          <Form.Item>
            <Button loading={loader} type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
};
export default AddNewTemplate;
