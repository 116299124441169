import { useCallback, useEffect, useState } from "react";

import { IVendor } from "../../vendor/types";
import { getById } from "../../services/apiService";
import { message } from "antd";

const useGetVendorById = (id: string) => {
  const [vendor, setVendor] = useState<IVendor>();
  const [loader, setLoader] = useState(false);

  const getVendorById = useCallback(async () => {
    setLoader(true);
    try {
      const { data } = await getById(`v1/vendors`, id);
      setVendor(data);
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoader(false);
  }, [id]);

  useEffect(() => {
    getVendorById();
  }, [getVendorById]);

  return {
    vendor,
    loader,
  };
};

export default useGetVendorById;
