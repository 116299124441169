import { ChangeEventHandler, useState } from "react";

import { BookingStatus } from "../../types/types";
import { Input } from "antd";
import OdinsonSelect from "../../components/odinsonSelect";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import querystring from "querystring";
import { returnQueryParams } from "../../utils/Utils";
import useGetVendors from "../../services/hooks/useGetVendors";
import { useHistory } from "react-router-dom";

type VendorOptionType = {
  name: string;
  id: string;
};

type CategoryType = {
  best_selling: boolean;
  created: number;
  description: string;
  id: number;
  info: string;
  is_active: boolean;
  is_deleted: boolean;
  is_parent: boolean;
  is_tripflix: boolean;
  name: string;
  parent_ids: number[];
  path: string;
  seo_description: string;
  seo_image: string;
  seo_keyword: string;
  seo_title: string;
  slug: string;
  sort_order: number;
  updated: number;
};

type ExperienceType = {
  short_title: string;
  title: string;
  id: number;
};

const OrderSearchAndFilters = () => {
  const history = useHistory();
  const queryParams = returnQueryParams(history.location);
  const [q, setQuery] = useState(queryParams.q);

  const debounceUrl = debounce((value) => {
    pushIntoUrl({ q: value, page: 1 });
  }, 1000);

  const onSearchChange = (e: ChangeEventHandler<HTMLInputElement> | any) => {
    const { value } = e.target as { value: string };
    setQuery(value);
    debounceUrl(value);
  };

  const pushIntoUrl = (params: any) => {
    const urlParams = {
      ...queryParams,
      ...params,
    };
    history.replace(`?${querystring.stringify(urlParams)}`);
  };

  const STATUS_OPTIONS = [
    { label: "Success", value: BookingStatus.SUCCESS },
    { label: "Failure", value: BookingStatus.FAILURE },
    { label: "Initiated", value: BookingStatus.INITIATED },
    { label: "Cancelled", value: BookingStatus.CANCELLED },
    { label: "Processing", value: BookingStatus.PROCESSING },
  ];

  const { state: vendors, loader } = useGetVendors("v1/vendors/");
  const { state: categories, loader: categoriesLoader } =
    useGetVendors("v1/categories/");
  const { state: experiences, loader: experienceLoader } = useGetVendors(
    `v1/packages/?limit=${220}&page=1&v2&use_pagination_v2=true`
  );
  const VENDOR_OPTIONS =
    !loader &&
    vendors.map((d: VendorOptionType) => ({ label: d.name, value: d.id }));

  const CATEGORIES_OPTIONS =
    !categoriesLoader &&
    categories.map((d: CategoryType) => ({ label: d.name, value: d.id }));

  const EXPERIENCE_OPTIONS =
    (!experienceLoader &&
      experiences?.data?.map((d: ExperienceType) => ({
        label: d.short_title,
        value: d.id,
      }))) ||
    [];

  const vendorOptionDefaultValue =
    VENDOR_OPTIONS &&
    VENDOR_OPTIONS.find(
      (it: any) => it.value === Number(queryParams.vendor_id)
    );
  const categoriesOptionsDefaultValue =
    CATEGORIES_OPTIONS &&
    CATEGORIES_OPTIONS.find(
      (it: any) => it.value === Number(queryParams.category_id)
    );
  const experienceOptionsDefaultValue =
    EXPERIENCE_OPTIONS &&
    EXPERIENCE_OPTIONS.find(
      (it: any) => it.value === Number(queryParams.package_id)
    );

  const statusOptionDefaultValue = STATUS_OPTIONS.find(
    (it) => it.value === queryParams.status
  );
  return (
    <div className="my-4">
      <Input
        onChange={onSearchChange}
        onPressEnter={onSearchChange}
        size="large"
        value={q}
        placeholder="Search across names, experience, order id and more"
        prefix={<SearchOutlined />}
      />
      <div className="my-4" />
      <p className="text-blue-700">Apply Filter:</p>
      <div className="flex justify-start">
        <div className="pr-2">
          <OdinsonSelect
            defaultValue={statusOptionDefaultValue}
            showSearch={false}
            placeholder="Select Status"
            handleChange={(value: any) => pushIntoUrl({ status: value?.value })}
            options={STATUS_OPTIONS}
          />
        </div>
        {loader ? (
          "..."
        ) : (
          <div className="pr-2">
            <OdinsonSelect
              defaultValue={vendorOptionDefaultValue}
              showSearch
              placeholder="Select Vendor"
              handleChange={(value: any) =>
                pushIntoUrl({ vendor_id: value?.value })
              }
              options={VENDOR_OPTIONS}
            />
          </div>
        )}

        {categoriesLoader ? (
          "..."
        ) : (
          <div className="pr-2">
            <OdinsonSelect
              defaultValue={categoriesOptionsDefaultValue}
              showSearch
              placeholder="Select Categories"
              handleChange={(value: any) =>
                pushIntoUrl({ category_id: value?.value })
              }
              options={CATEGORIES_OPTIONS}
            />
          </div>
        )}
        {experienceLoader ? (
          "..."
        ) : (
          <div className="pr-2">
            <OdinsonSelect
              defaultValue={experienceOptionsDefaultValue}
              showSearch
              placeholder="Select Experience"
              handleChange={(value: any) =>
                pushIntoUrl({ package_id: value?.value })
              }
              options={EXPERIENCE_OPTIONS}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSearchAndFilters;
