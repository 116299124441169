import "antd/es/date-picker/style/index";

import { TimePicker, TimeRangePickerProps } from "antd";

import { FC } from "react";

const TimeRangePicker: FC<{
  defaultValues?: any;
  handleChange: (values: any, formatString: [string, string]) => void;
}> = ({ defaultValues, handleChange }) => {
  const rangeProps: TimeRangePickerProps = {
    placeholder: ["Start Time", "End Time"],
    onChange: handleChange,
  };
  if (defaultValues) {
    console.log(defaultValues);
    rangeProps.defaultValue = defaultValues;
  }
  return <TimePicker.RangePicker {...rangeProps} />;
};
export default TimeRangePicker;
