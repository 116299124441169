import { FC, useCallback, useState } from "react";

import { IExperience } from "./types";
import SearchAndSelect from "../../components/SearchAndSelect";
import { Select } from "antd";
import { debounce } from "lodash";
import { getByQueryParam } from "../../services/apiService";

const { Option } = Select;

const URL_EXPERIENCES = "v1/packages/";

type SearchExperienceProps = {
  selectedExperience: IExperience;
  handleSetValue: (name: string, value: number | string) => void;
  setSelectedExperience: Function;
};

const SearchExperience: FC<SearchExperienceProps> = ({
  handleSetValue,
  setSelectedExperience,
  selectedExperience,
}) => {
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const searchPackage = useCallback(async (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    setLoader(true);
    const { data } = await getByQueryParam(URL_EXPERIENCES, { q: value });
    setLoader(false);
    setOptions(data);
  }, []);

  const debouncedPackageSearch = debounce(searchPackage, 1000);
  const getLabel = () => {
    if (!selectedExperience) return "";
    return `${selectedExperience.id} | ${selectedExperience.title} | ${selectedExperience.short_title}`;
  };
  return (
    <SearchAndSelect
      defaultValue={{
        value: selectedExperience?.id,
        label: getLabel(),
      }}
      fetching={loader}
      placeholder="Search experience"
      setValue={(value) => {
        handleSetValue("package_id", value.value);
        const selectedExperience = options.find(
          (experience: any) => experience.id === value.value
        );
        setSelectedExperience(selectedExperience);
      }}
      fetchOptions={debouncedPackageSearch}
      options={options.map((op: IExperience) => (
        <Option value={op.id} key={op.id}>
          [{op.id}] - [{op.title}] - [{op.short_title}]
        </Option>
      ))}
    />
  );
};
export default SearchExperience;
