import { Tag, Tooltip } from "antd";
import { FC, ReactNode } from "react";

const COLOR = "geekblue";

type UserDetailsProps = {
  user: {
    id: number;
    name: string;
    email: string;
    mobile: number;
    country_code: string;
  };
};
const UserDetails: FC<UserDetailsProps> = ({ user }) => {
  return (
    <Tooltip color={COLOR} placement="top" title={<UserData user={user} />}>
      <Tag color={COLOR}>View User</Tag>
    </Tooltip>
  );
};
export default UserDetails;

const renderRow = (label: string, value: string | number | ReactNode) => (
  <div>
    <p className="mb-2 text-xs">
      <span className="font-extralight">{label}: &nbsp;</span>
      {value ?? "--"}
    </p>
  </div>
);

export const UserData = ({ user }: UserDetailsProps) => {
  return (
    <div className="p-2">
      {renderRow("ID", user.id)}
      {renderRow("Name", user.name)}
      {renderRow("Mobile", user.mobile)}
      {renderRow("Email", user.email)}
      {renderRow("C. Code", user.country_code)}
    </div>
  );
};
