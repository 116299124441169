import { ChangeEventHandler, FC, ReactNode, useState } from "react";
import { Divider, Input, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { post } from "../services/apiService";

type AddTagProps = {
    menu: ReactNode,
    tags: Array<{ id: string, name: string }>,
    updateTags: () => Promise<void>
}
const AddTag: FC<AddTagProps> = ({ menu, tags, updateTags }) => {
    const [newTag, setNewTag] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);

    const setTag = (e: ChangeEventHandler<HTMLInputElement> | any) => {
        const { value } = e.target;
        setNewTag(value)
    };

    const addTag = async () => {
        setLoader(true)
        try {
            const payload = [...tags.map((tag: any) => tag?.name), newTag]
            await post('v1/blogs/tags/', { tags: payload })
            setNewTag('');
            updateTags()
            setLoader(false)
        }
        catch (err) {
            message.error('Something went wrong;')
        }
        setLoader(false)
    }

    return <div>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input style={{ flex: 'auto' }} value={newTag} name='newTag' onChange={setTag} />
            <Button
                disabled={!newTag}
                loading={loader}
                type='primary'
                onClick={addTag}
            >
                <PlusOutlined /> Add item
            </Button>
        </div>
    </div>
}

export default AddTag