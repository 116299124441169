import { FC } from "react";
import { IVendor } from "../types";
import { Link } from "react-router-dom";
import { RiseOutlined } from "@ant-design/icons";
import { kebabCase } from "lodash";

const GoToActionButtonVMS: FC<{ vendor: IVendor }> = ({ vendor }) => (
  <Link
    className="bg-gray-600 p-1 px-2 flex items-center text-white hover:text-white rounded"
    to={`/${kebabCase(vendor.name)}/dashboard/${vendor.id}/my-bookings`}
  >
    Go to my Dashboard
    <div className="px-1" />
    <RiseOutlined />
  </Link>
);
export default GoToActionButtonVMS;
