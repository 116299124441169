import { DateFormat, formatDate } from "../utils/formatDate";
import { Route, Switch, useParams } from "react-router-dom";

import ErrorCard from "../components/ErrorCard";
import Loader from "../loader/Loader";
import { UserRoles } from "../entities/user";
import VendorBookings from "./layouts/vendorBookings";
import VendorCard from "./layouts/vendorCard";
import VendorMenu from "./layouts/vendorMenu";
import VendorPackageSummary from "./layouts/vendorPackageSummary";
import { VendorParamsType } from "./types/types";
import VendorPayouts from "./layouts/vendorPayouts";
import VendorReviews from "./layouts/vendorReviews";
import { useAuth } from "../services/AuthProvider";
import useGetVendorById from "./services/useGetVendorById";

const VendorManagementSystem = () => {
  const { user } = useAuth();
  const ifUserIsVendor = user?.role === UserRoles.VENDOR;
  const { id } = useParams<VendorParamsType>();

  const { vendor, loader } = useGetVendorById(id);
  if (loader) return <Loader />;
  if (!vendor) return <ErrorCard />;
  const startDate = formatDate(
    vendor.created,
    DateFormat.serverDateFormat,
    true
  );
  return (
    <>
      <VendorCard vendor={vendor} />
      <VendorMenu />
      <Switch>
        <Route
          exact
          path="/:name/dashboard/:id/my-bookings"
          render={(props) => (
            <VendorBookings
              {...props}
              ifUserIsVendor={ifUserIsVendor}
              start_date={startDate}
            />
          )}
        />
        <Route
          exact
          path="/:name/dashboard/:id/my-summary"
          component={VendorPackageSummary}
        />
        <Route
          exact
          path="/:name/dashboard/:id/my-payouts"
          component={VendorPayouts}
        />
        <Route
          exact
          path="/:name/dashboard/:id/my-reviews"
          component={VendorReviews}
        />
      </Switch>
    </>
  );
};
export default VendorManagementSystem;
