import { useCallback, useEffect, useMemo, useState } from "react";

import { message } from "antd";
import querystring from "querystring";
import { useHistory } from "react-router-dom";
import { LIMIT } from "../../constants/Constants";
import { returnQueryParams } from "../../utils/Utils";
import { getByQueryParam } from "../apiService";

type GetListReturnType = {
  state: any;
  error: boolean;
  loader: boolean;
  page: string | string[];
};

const useGetList = (
  link: string,
  queryParams: querystring.ParsedUrlQueryInput = {}
): GetListReturnType => {
  const history = useHistory();
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  const {
    page = "1",
    q,
    status,
    vendor_id,
    category_id,
    package_id,
  } = useMemo(() => returnQueryParams(history.location), [history.location]);
  const {
    start_date,
    end_date,
    exclude_large_duration_orders,
    experience_id,
    customPage,
    customLimit,
  } = queryParams;
  const getList = useCallback(async () => {
    try {
      setLoader(true);
      const params: any = {
        page: customPage ?? page,
        limit: customLimit ?? LIMIT,
        use_pagination_v2: true,
      };
      if (q) params.q = q;
      if (status) params.status = status;
      if (vendor_id) params.vendor_id = vendor_id;
      if (start_date) params.start_date = start_date;
      if (end_date) params.end_date = end_date;
      if (experience_id) params.experience_id = experience_id;
      if (category_id) params.categories = [category_id];
      if (package_id) params.packages = [package_id];
      if (exclude_large_duration_orders)
        params.exclude_large_duration_orders = exclude_large_duration_orders;
      const { data } = await getByQueryParam(link, params);
      setState(data);
      setLoader(false);
      setError(false);
    } catch (err) {
      setLoader(false);
      setError(true);
      message.error("Something went wrong!");
    }
  }, [
    link,
    page,
    q,
    status,
    vendor_id,
    start_date,
    end_date,
    exclude_large_duration_orders,
    experience_id,
    customPage,
    category_id,
    package_id,
    customLimit,
  ]);

  useEffect(() => {
    getList();
  }, [getList]);

  return {
    state,
    error,
    loader,
    page,
  };
};
export default useGetList;
