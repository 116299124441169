import { Link } from "react-router-dom";
import { Spin } from "antd";
import useGetExperiences from "./services/useGetExperiences";

const ListExperiences = () => {
  const { experiences, loading, error } = useGetExperiences();
  if (loading) return <Spin />;
  if (error) return <p>Something went wrong.</p>;
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          {experiences.map((experience, i) => (
            <p>
              <Link
                to={`/edit-experiences/${experience.id}`}
                key={experience.id}
              >
                {i + 1} - {experience.title} - {experience.id}
              </Link>
            </p>
          ))}
        </>
      )}
    </>
  );
};
export default ListExperiences;
