import { FC, ReactNode } from "react";

import useGetTableData from "../../services/hooks/useGetTableData";

type CustomTableProps = {
  data: Array<any>;
  filterKeys?: Array<string>;
  children: ReactNode;
  addKeys?: Array<{ key: string; position: number }>;
};

const CustomTable: FC<CustomTableProps> = ({
  data,
  children,
  filterKeys = [],
  addKeys = [],
}) => {
  const { tableColumns } = useGetTableData(data, filterKeys, addKeys);
  return (
    <div className="flex flex-col mb-4 text-sm">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {tableColumns.map(({ title }) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-sm">
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;

export const TD: FC<{ children: ReactNode }> = ({ children }) => (
  <td className="px-6 py-4 whitespace-nowrap">{children}</td>
);
