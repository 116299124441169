import { useEffect, useState } from "react";

import { IExperience } from "../types";
import { get } from "../../services/apiService";

const useGetExperiences = () => {
  const [experiences, setExperiences] = useState<IExperience[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getExperiences = async () => {
    setLoading(true);
    try {
      const { data } = await get("/v1/packages/");
      setExperiences(data);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExperiences();
  }, []);

  return { experiences, loading, error };
};
export default useGetExperiences;
