import { FC, useCallback, useEffect, useMemo } from "react";

import { LIMIT } from "../../constants/Constants";
import { Pagination } from "antd";
import querystring from "querystring";
import { returnQueryParams } from "../../utils/Utils";
import { useHistory } from "react-router-dom";

type StringOrStringArray = string | string[];

interface ICustomPagination {
  total: number;
  showSizeChanger?: boolean;
  showQuickJumper: boolean;
  page: StringOrStringArray;
}

type PaginationParamsType = {
  page?: StringOrStringArray;
  q?: StringOrStringArray;
  status?: StringOrStringArray;
  vendor_id?: StringOrStringArray;
  start_date?: StringOrStringArray;
  category_id?: StringOrStringArray;
  package_id?: StringOrStringArray;
};

const CustomPagination: FC<ICustomPagination> = ({
  total,
  showSizeChanger,
  showQuickJumper,
  page,
}) => {
  const history = useHistory();
  // Replace returnQueryParams from useQueryParams
  const { q, status, vendor_id, category_id, package_id } = useMemo(
    () => returnQueryParams(history.location),
    [history.location]
  );

  const pushIntoUrl = useCallback(
    (page) => {
      const params: PaginationParamsType = {
        page,
      };
      if (q) params.q = q;
      if (status) params.status = status;
      if (vendor_id) params.vendor_id = vendor_id;
      if (category_id) params.category_id = category_id;
      if (package_id) params.package_id = package_id;

      history.replace(`?${querystring.stringify(params)}`);
    },
    [history, q, status, vendor_id, category_id, package_id]
  );

  useEffect(() => {
    pushIntoUrl(page);
  }, [pushIntoUrl, page]);

  return (
    <Pagination
      showSizeChanger={false}
      defaultPageSize={LIMIT}
      className="flex justify-end"
      total={total}
      showQuickJumper={showQuickJumper}
      showTotal={(total) => `Total ${total} items`}
      onChange={pushIntoUrl}
      current={Number(page)}
    />
  );
};

export default CustomPagination;
