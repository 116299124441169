import {
  Basket,
  BasketPayload,
  ExperienceRoot,
  FormValues,
  ImagesRoot,
} from "./types";
import { Button, Form, Input, PageHeader, Select, Spin, message } from "antd";
import { getByIdTyped, patchWithId, post } from "../services/apiService";
import { useMutation, useQuery } from "react-query";

import Editor2 from "../editor/Editor2";
import { OfferingsRoot } from "../offerings/types";
import { debounce } from "lodash";
import { useEffect } from "react";
import { default as useGetBasketValues } from "./services/useGetBasketValues";
import { useParams } from "react-router-dom";

const { Option } = Select;

const AddBasket = () => {
  const [form] = Form.useForm();
  const params = useParams() as { id: string };
  const { isLoading, mutate } = useMutation(
    (payload: BasketPayload) => {
      if (params.id) {
        return patchWithId(`v1/baskets`, params.id, payload);
      }
      return post("/v1/baskets/", payload);
    },
    {
      onSuccess: () => {
        message.success("Basket added successfully");
      },
      onError: () => {
        message.error("Error adding basket");
      },
    }
  );
  const { data: basket } = useQuery<Basket, Error>(
    "basketItem",
    async () => {
      const { data } = await getByIdTyped<Basket>("v1/baskets", params.id);
      return data;
    },
    {
      enabled: !!params.id,
    }
  );
  const {
    fetch: fetchImage,
    data: images,
    loading: imageLoading,
  } = useGetBasketValues<ImagesRoot>();
  const {
    fetch: fetchExperience,
    data: experiences,
    loading: experienceLoader,
  } = useGetBasketValues<ExperienceRoot>();
  const {
    fetch: fetchOfferings,
    data: offerings,
    loading: offeringsLoader,
  } = useGetBasketValues<OfferingsRoot>();
  useEffect(() => {
    if (params.id && basket) {
      form.setFieldsValue({
        title: basket.title,
        short_title: basket.short_title,
        keywords: basket.keywords,
        price: basket.price,
        description: basket.description,
        image_ids: basket.images.map((image) => image.id),
        experience_ids: basket.experiences.map((experience) => experience.id),
        offering_ids: basket.offerings.map((offering) => offering.id),
        body: basket.body,
      });
    }
  }, [params.id, basket, form]);
  const onFinish = (values: FormValues) => {
    type ExpValue = {
      value: number;
    };
    const payload: BasketPayload = {
      ...values,
      image_ids: values?.image_ids?.map((id) => ({ id })),
      experience_ids: values?.experience_ids?.map((value) => {
        const newValue: ExpValue = { value };
        return {
          id: newValue.value,
        };
      }),
      blog_ids: values?.blog_ids?.map((id) => ({ id })),
      offering_ids: values?.offering_ids?.map((id) => ({ id })),
      tag_ids: values?.tag_ids?.map((id) => ({ id })),
    };
    mutate(payload);
  };
  const handleSearch = (value: string, searchType: string) => {
    if (value.length > 1) {
      switch (searchType) {
        case "image": {
          fetchImage("v1/uploads/images/", value);
          break;
        }
        case "experience": {
          fetchExperience("v1/packages/", value);
          break;
        }
        case "offerings": {
          fetchOfferings("v1/offerings/", value);
          break;
        }
        default:
          throw new Error("Unknown search type");
      }
    }
  };
  const debouncedHandleSearch = debounce(handleSearch, 1000);

  return (
    <div className="bg-white p-4 rounded">
      <PageHeader title={`${params.id ? "Edit" : "Add"} curated basket`} />
      <Form
        className="grid grid-cols-2 gap-8"
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Short title"
          name="short_title"
          rules={[{ required: true, message: "Please input short title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Keywords"
          name="keywords"
          rules={[{ required: true, message: "Please input keywords" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please input description" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input price" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Images"
          name="image_ids"
          rules={[{ required: true, message: "Please select images" }]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Search and select images"
            allowClear
            onChange={(value: number[]) => {
              form.setFieldsValue({ image_ids: value });
            }}
            notFoundContent={
              imageLoading ? <Spin size="small" /> : "No data found"
            }
            onSearch={(value) => {
              debouncedHandleSearch(value, "image");
            }}
          >
            {images?.data.map((image) => (
              <Option key={image.id} value={image.id}>
                <div className="flex gap-2">
                  <img
                    src={image.urls.jpeg}
                    alt={image.name}
                    className="w-20 object-contain h-20 rounded"
                  />
                  <div className="fs-16 font-bold">{image.name}</div>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Offerings"
          name="offering_ids"
          rules={[{ required: false, message: "Please select offerings" }]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Search and select images"
            allowClear
            onChange={(value: number[]) => {
              form.setFieldsValue({ offering_ids: value });
            }}
            notFoundContent={
              offeringsLoader ? <Spin size="small" /> : "No data found"
            }
            onSearch={(value) => {
              debouncedHandleSearch(value, "offerings");
            }}
          >
            {offerings?.data.map((item) => (
              <Option key={item.id} value={item.id}>
                <div className="flex gap-2">
                  <div className="fs-16 font-bold">{item.title}</div>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Experiences"
          name="experience_ids"
          rules={[{ required: true, message: "Please select experience" }]}
        >
          <Select
            showSearch
            mode="multiple"
            allowClear
            placeholder="Search and select experiences"
            notFoundContent={
              experienceLoader ? <Spin size="small" /> : "No data found"
            }
            onChange={(value: number[]) => {
              form.setFieldsValue({ experience_ids: value });
            }}
            onSearch={(value) => {
              debouncedHandleSearch(value, "experience");
            }}
          >
            {experiences?.data.map((experience) => (
              <Option key={experience.id} value={experience.id}>
                {experience.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="col-span-2"
          name="body"
          label="Body"
          rules={[{ required: false, message: "Please input body" }]}
        >
          {basket?.body && (
            <Editor2
              initialValue={basket?.body}
              saveData={(value: string) => {
                form.setFieldsValue({ body: value });
              }}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            htmlType="submit"
          >
            <span>Submit</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBasket;
