import CustomTable, { TD } from "../../components/odinsonTable";

import { DateFormat } from "../../utils/formatDate";
import { IVendorReviews } from "../types/types";
import { StarIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";

type VendorReviewsTableProps = {
  reviews: IVendorReviews[];
};
const FILTER_KEYS = [
  "is_active",
  "is_deleted",
  "notifications",
  "vendor_extra",
  "booking_extra",
  "experience_extra",
  "show_on_home_page",
  "updated",
  "order_id",
  "booking_rating",
  "booking_review",
  "user",
];

const VendorReviewsTable = ({ reviews }: VendorReviewsTableProps) => {
  const getRatingColor = (rating: number) => {
    if (rating >= 4) {
      return "text-green-600";
    } else if (rating >= 3) {
      return "text-red-300";
    }
    return "text-red-800";
  };

  return (
    <>
      <CustomTable filterKeys={FILTER_KEYS} data={reviews}>
        {reviews.map((review: IVendorReviews) => {
          return (
            <tr key={review.id}>
              <TD>{review.id}</TD>
              <TD>
                {dayjs.unix(review.created).format(DateFormat.clientDateFormat)}
              </TD>
              <TD>
                <div
                  className={`font-bold flex gap-2 ${getRatingColor(
                    review.experience_rating
                  )}`}
                >
                  <div>{review.experience_rating}</div>
                  <StarIcon height={18} />
                </div>
              </TD>
              <td className="px-6 py-4">{review.experience_review}</td>
              <TD>{review.package.name}</TD>
            </tr>
          );
        })}
      </CustomTable>
    </>
  );
};

export default VendorReviewsTable;
