import Locker from "lockr";
import api from "./apiService";

const TOKEN = "auth_token";

export function authorise(token: string) {
  Locker.set(TOKEN, token);
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function unauthorise() {
  Locker.rm(TOKEN);
  api.defaults.headers.common["Authorization"] = null;
}

export function getToken(): string {
  return Locker.get(TOKEN);
}

export function isAuthenticated(): boolean {
  return !!Locker.get(TOKEN);
}
