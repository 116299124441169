import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { CSSProperties, FC, ReactElement } from "react";

export type OdinsonOption = {
  label: string;
  value: string | number;
};

export type OdinsonOptions = OdinsonOption[];

type OdinsonSelectProps = {
  handleChange: any;
  options: OdinsonOptions;
  placeholder: string;
  showSearch?: boolean;
  disabled?: boolean;
  defaultValue?: { label: string; value: string | number } | any;
  style?: CSSProperties;
  size?: SizeType;
  className?: string;
  dropdownRender?: (menu: ReactElement) => ReactElement;
};

const { Option } = Select;

const OdinsonSelect: FC<OdinsonSelectProps> = ({
  handleChange,
  options,
  placeholder,
  showSearch,
  disabled,
  defaultValue,
  style,
  size,
  className,
  dropdownRender,
}) => {
  return (
    <Select
      className={className}
      labelInValue
      style={style}
      defaultValue={defaultValue}
      disabled={disabled}
      allowClear
      size={size}
      showSearch={showSearch}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={handleChange}
      dropdownRender={dropdownRender}
    >
      {options?.map((op) => (
        <Option key={op.value} value={op.value}>
          {op.label}
        </Option>
      ))}
    </Select>
  );
};

export default OdinsonSelect;
