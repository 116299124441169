import { Spin } from "antd";
import { FC } from "react";
import ErrorCard from "../../components/ErrorCard";
import OdinsonSelect from "../../components/odinsonSelect";
import useGetTemplate from "../../template/services/useGetTemplates";
import { CommunicationType } from "../../template/types";
import { CampaignType } from "./types";
const ListCommunicationTemplate: FC<{
  handleChange: (id: number, list: CampaignType[]) => void;
}> = ({ handleChange }) => {
  const { list, loader, error } = useGetTemplate(CommunicationType.EMAIL);
  if (loader) return <Spin />;
  if (error) return <ErrorCard />;
  return (
    <>
      <label>Select or add new template</label>
      <OdinsonSelect
        style={{ width: "100%", marginBottom: "1rem" }}
        options={list.map((it: CampaignType) => ({
          label: it.name,
          value: it.id,
        }))}
        handleChange={(value: any) => handleChange(value.key, list)}
        placeholder={"Select or add new template"}
      />
    </>
  );
};
export default ListCommunicationTemplate;
