import { useCallback, useState } from "react";

import { message } from "antd";
import { post } from "../../services/apiService";

type queryParamsType = string | string[] | undefined;

type PayloadType = {
  start_date: queryParamsType;
  end_date: queryParamsType;
  exclude_order_ids?: string[];
  experience_id?: string | number;
  exclude_large_duration_orders: boolean;
};

const useGeneratePayoutSummary = (
  vendor_id: string,
  start_date: string | string[] | undefined,
  end_date: string | string[] | undefined,
  exclude_order_ids: string[] = [],
  experience_id: string | number | undefined,
  exclude_large_duration_orders: boolean
) => {
  const [loader, setLoader] = useState(false);
  const [summary, setSummary] = useState();
  const generatePayoutSummary = useCallback(async () => {
    setLoader(true);
    const payload: PayloadType = {
      start_date,
      end_date,
      exclude_large_duration_orders,
    };
    if (exclude_order_ids) payload.exclude_order_ids = exclude_order_ids;
    if (experience_id) payload.experience_id = experience_id;
    if (exclude_large_duration_orders)
      payload.exclude_large_duration_orders = exclude_large_duration_orders;
    try {
      const { data } = await post(
        `v1/vendors/${vendor_id}/payouts/summary/`,
        payload
      );
      setSummary(data);
    } catch (err: any) {
      message.error("Something went wrong");
    }
    setLoader(false);
  }, [
    exclude_order_ids,
    start_date,
    end_date,
    vendor_id,
    experience_id,
    exclude_large_duration_orders,
  ]);

  return {
    summary,
    generatePayoutSummary,
    loader,
  };
};

export default useGeneratePayoutSummary;
