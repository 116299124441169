import { FC, memo } from "react";

import { LIMIT } from "../../constants/Constants";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import ShowPageOfTotalPage from "../showPageOfTotalPage";

type ActionHeaderProps = {
  page: string | string[];
  totalCount: number;
  actionLink?: string;
  actionText?: string;
};
const ActionHeader: FC<ActionHeaderProps> = ({
  page,
  totalCount,
  actionLink,
  actionText,
}) => {
  return (
    <div className="flex justify-between items-center mb-4">
      <div>
        <ShowPageOfTotalPage
          page={page}
          totalCount={totalCount}
          limit={LIMIT}
        />
      </div>
      {actionLink && (
        <div>
          <Link
            to={actionLink}
            className="bg-green-500 p-3 px-5 flex items-center text-white hover:text-white rounded"
          >
            <PlusOutlined color="#FFF" />{" "}
            <span className="pl-1">{actionText}</span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default memo(ActionHeader);
