import { FC, useCallback, useState } from "react";

import { IUser } from "./types";
import SearchAndSelect from "../../components/SearchAndSelect";
import { Select } from "antd";
import { debounce } from "lodash";
import { getByQueryParam } from "../../services/apiService";

const { Option } = Select;

const URL_USERS = "v1/users/";
type SearchUserProps = {
  handleSetValue: (name: string, value: number | string) => void;
  user: IUser | null;
};
const SearchUser: FC<SearchUserProps> = ({ handleSetValue, user }) => {
  const [options, setOptions] = useState<IUser[]>([]);
  const [loader, setLoader] = useState(false);

  const searchUser = useCallback(async (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    setLoader(true);
    const { data } = await getByQueryParam(URL_USERS, { q: value });
    setLoader(false);
    setOptions(data);
  }, []);

  const debouncedUserSearch = debounce(searchUser, 1000);

  const label = (user: IUser | null) => {
    if (!user) return "";
    return `[${user?.id ?? "--"}] | [${user?.name ?? "--"}] | [${
      user?.email ?? "--"
    }] | [
    ${user?.mobile ?? "--"}]`;
  };

  return (
    <SearchAndSelect
      disabled={!!user?.id}
      defaultValue={{
        value: user?.id,
        label: label(user),
      }}
      fetching={loader}
      placeholder="Search User"
      setValue={(value) => handleSetValue("user_id", value?.value)}
      fetchOptions={debouncedUserSearch}
      options={options.map((op: IUser) => (
        <Option value={op.id} key={op.id}>
          {label(op)}
        </Option>
      ))}
    />
  );
};

export default SearchUser;
