import { Button, Modal, Tag } from "antd";
import { FC, useState } from "react";

import { CopyOutlined } from "@ant-design/icons";
import querystring from "querystring";
import { copyToClipboard } from "../../utils/Utils";

type ImportantLinksProps = {
  id: string;
  title: string;
  paymentLink: string | undefined;
  url: string;
  experienceId: number;
  isVendor?: boolean;
};
const ImportantLinks: FC<ImportantLinksProps> = ({
  id,
  title,
  paymentLink: PAYMENT_LINK,
  url,
  experienceId,
  isVendor,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const attachWithBaseURL = (url: string) =>
    `https://www.bwtexperiences.com/${url}`;
  const REVIEW = attachWithBaseURL(
    `post-review/${id}?${querystring.stringify({ title })}`
  );
  const JOINING_LINK = attachWithBaseURL(`join/${id}/${url}?referrer=admin`);
  const CHECKOUT = attachWithBaseURL(`checkout/${experienceId}`);
  return (
    <>
      <Button
        type="primary"
        children="Open Important Links"
        size="small"
        onClick={() => setModalVisible(true)}
      />
      <Modal
        title="Important Link"
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <Link label="Review" link={REVIEW} />
        {!isVendor && <Link label="Payment Link" link={PAYMENT_LINK} />}
        {!isVendor && <Link label="Checkout Link" link={CHECKOUT} />}
        {url && !isVendor && <Link label="Joining Link" link={JOINING_LINK} />}
      </Modal>
    </>
  );
};

export default ImportantLinks;

export const Link: FC<{ label: string; link: string | undefined }> = ({
  label,
  link,
}) => {
  if (!link) return <></>;
  return (
    <div
      className="cursor-pointer text-blue-600 flex items-center justify-between border-b-2 p-2"
      onClick={() => copyToClipboard(link)}
    >
      <Tag>{label}</Tag>
      <CopyOutlined />
    </div>
  );
};
