import OdinsonSelect, {
  OdinsonOption,
  OdinsonOptions,
} from "../../components/odinsonSelect";
import dayjs, { Dayjs } from "dayjs";

import { Button } from "antd";
import DateRangePicker from "../../components/DateRangePicker";
import { FC } from "react";
import OdinsonSwitch from "../../components/odinsonSwitch";

type VendorBookingActionBarProps = {
  filterDateRange: (value: Dayjs[]) => void;
  generateSummary: () => void;
  start_date: string;
  handleDurationChange: () => void;
  defaultChecked: boolean;
  switchLabel?: string;
  ifUserIsVendor: boolean;
  vendorExpOptions: OdinsonOptions;
  onExperiencesSelected: (option: OdinsonOption) => void;
};

const VendorBookingActionBar: FC<VendorBookingActionBarProps> = ({
  filterDateRange,
  generateSummary,
  start_date,
  handleDurationChange,
  defaultChecked,
  switchLabel,
  ifUserIsVendor,
  vendorExpOptions,
  onExperiencesSelected,
}) => {
  const disabledDate = (current: any) => {
    return (
      current <
        (dayjs(start_date).get("year") >= 2021
          ? dayjs(start_date)
          : dayjs("01-01-2021")) || current > dayjs().endOf("day")
    );
  };
  return (
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center">
        <div>
          <DateRangePicker
            disabledData={disabledDate}
            handleChange={filterDateRange}
          />
        </div>
        <div className="px-2" />
        <div>
          <OdinsonSelect
            showSearch
            style={{ width: "300px" }}
            size={"middle"}
            placeholder={"Select Experiences"}
            options={vendorExpOptions}
            handleChange={onExperiencesSelected}
          />
        </div>
        <div className="px-2" />

        {!ifUserIsVendor && (
          <div className="flex">
            <div>Duration</div>
            <div className="px-2">
              <OdinsonSwitch
                switchLabel={switchLabel}
                defaultChecked={defaultChecked}
                onChange={handleDurationChange}
              />
            </div>
          </div>
        )}
      </div>
      {!ifUserIsVendor && (
        <div>
          <Button
            className="justify-self-end"
            onClick={generateSummary}
            children="Generate Payout Summary"
            type="primary"
          />
        </div>
      )}
    </div>
  );
};
export default VendorBookingActionBar;
