import { useCallback, useEffect, useState } from "react";

import { IExperience } from "../types";
import { getById } from "../../services/apiService";

const useGetExperiencesById = (id: string) => {
  const [experience, setExperience] = useState<IExperience | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getExperienceById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getById("/v1/packages", id);
      setExperience(data);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getExperienceById();
  }, [getExperienceById]);

  return { experience, loading, error };
};
export default useGetExperiencesById;
