import { message } from "antd";
import { post } from "../../services/apiService";
import { useState } from "react";

type UploadEmailBulkType = {
  csv_file: string | Blob;
  body: string;
  subject: string;
  channel: string;
};

const useSendBulkEmail = (state: UploadEmailBulkType) => {
  const [loader, setLoader] = useState(false);
  const broadCastEmail = async () => {
    setLoader(true);
    const data = new FormData();
    data.append("body", state.body);
    data.append("csv_file", state.csv_file);
    data.append("subject", state.subject);
    data.append("channel", state.channel);
    try {
      await post("v1/communications/", data, {
        "Content-Type": "multipart/form-data",
      });
      message.success("Email sent successfully.");
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    }
    setLoader(false);
  };

  return {
    broadCastEmail,
    loader,
  };
};
export default useSendBulkEmail;
