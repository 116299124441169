import { Button } from "antd";
import React, { useRef } from "react";
import EmailEditor from "react-email-editor";

const OdinsonEmailEditor = ({
  onSave,
  showSaveButton = true,
  load,
}: {
  onSave: (body: string) => void;
  showSaveButton?: boolean;
  load?: string;
}) => {
  const emailEditorRef = useRef<any>(null);

  const exportHtml = () => {
    if (emailEditorRef?.current?.editor) {
      emailEditorRef.current?.editor?.exportHtml((data: any) => {
        onSave(JSON.stringify(data));
      });
    }
  };
  const onLoad = () => {
    if (load) {
      const templateJson = JSON.parse(load);
      emailEditorRef.current.editor.loadDesign(templateJson?.design);
    }
  };

  return (
    <div className="my-4">
      <EmailEditor onLoad={onLoad} ref={emailEditorRef} />

      {showSaveButton && (
        <Button
          className="bg-blue-500 text-white p-2 rounded mt-3"
          onClick={exportHtml}
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default OdinsonEmailEditor;
