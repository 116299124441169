import { patch, post } from "../../services/apiService";
import { useCallback, useState } from "react";

import { message } from "antd";
import { useParams } from "react-router-dom";

type FormValues = {
  name: string;
  email: string;
  mobile: string;
  country_code: string;
  role_id: number;
};
const useUpdateUser = () => {
  // get id from url
  const { id } = useParams<{ id: string }>();
  // loader & error state
  const [loading, setLoading] = useState(false);
  // get country codes

  // create user
  const createUser = useCallback(async (values: FormValues) => {
    const payload = {
      ...values,
      role_id: 1,
    };
    try {
      await post("v1/users/", payload);
      message.success("User added successfully");
    } catch (err) {
      message.error("Error adding user");
    }
  }, []);

  // update user
  const updateUser = useCallback(
    async (values: FormValues) => {
      try {
        await patch(`v1/users/${id}/`, values);
        message.success("User updated successfully");
      } catch (err) {
        message.error("Error updating user");
      }
    },
    [id]
  );
  // handleFinish function
  const handleFinish = useCallback(
    (values: FormValues) => {
      setLoading(true);
      const payload = {
        ...values,
      };
      try {
        if (id) updateUser(payload);
        else createUser(payload);
      } catch (error) {
        message.error((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [id, updateUser, createUser]
  );

  // return loader and handleFinish
  return {
    loading,
    handleFinish,
  };
};
export default useUpdateUser;
