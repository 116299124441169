import CustomTable, { TD } from "../components/odinsonTable";
import { PageHeader, Pagination } from "antd";
import { useCallback, useState } from "react";

import { BasketRoot } from "./types";
import ErrorCard from "../components/ErrorCard";
import { Link } from "react-router-dom";
import Loader from "../loader/Loader";
import { get } from "../services/apiService";
import { useQuery } from "react-query";

const ListBasket = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, isError } = useQuery<BasketRoot, Error>(
    ["baskets", params.page],
    async () => {
      const { data } = await get(`v1/baskets/`, params);
      return data;
    }
  );
  const handlePageChange = useCallback(
    (page: number) => {
      setParams({ ...params, page });
    },
    [params]
  );
  if (isLoading) return <Loader />;
  if (isError) return <ErrorCard />;
  return (
    <div>
      <PageHeader title="Curated Basket List" />
      <CustomTable
        data={data?.data as any[]}
        filterKeys={["tags", "images", "blogs", "experiences", "offerings"]}
      >
        {data?.data.map((item) => (
          <tr>
            <TD>
              <Link to={`/edit-basket/${item.id}`}>{item.id}</Link>
            </TD>
            <TD>{item.title} </TD>
            <TD>{item.short_title}</TD>
            <TD>{item.price}</TD>
          </tr>
        ))}
      </CustomTable>
      <Pagination onChange={handlePageChange} />
    </div>
  );
};

export default ListBasket;
