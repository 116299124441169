import { Modal } from "antd";
import { FC, memo, ReactNode } from "react";

type OdinsonModalProps = {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  children: ReactNode;
  title: string | ReactNode;
};

const OdinsonModal: FC<OdinsonModalProps> = ({
  visible,
  handleOk,
  handleCancel,
  children,
  title,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};
export default memo(OdinsonModal);
