import { FC, ReactNode } from "react";
import { Tag, Tooltip } from "antd";

type ExperienceDetailsProps = {
  experience: {
    id: number;
    instructor_name: string;
    is_completed: boolean;
    joining_details: string;
    message: string;
    name: string;
    pickup: string;
    things_to_carry: string;
    time_slot: string;
  };
};
const COLOR = "purple";

const ExperienceDetails: FC<ExperienceDetailsProps> = ({ experience }) => {
  return (
    <Tooltip
      color={COLOR}
      placement="top"
      title={<ExperienceData experience={experience} />}
    >
      <Tag color={COLOR}>View Experience</Tag>
    </Tooltip>
  );
};
export default ExperienceDetails;

const renderRow = (label: string, value: string | number | ReactNode) => {
  if (!value) return;
  return (
    <div>
      <p className="mb-2 text-xs">
        <span className="font-extralight">{label}: &nbsp;</span>
        <span>{value ?? "--"}</span>
      </p>
    </div>
  );
};

export const ExperienceData = ({ experience }: ExperienceDetailsProps) => {
  return (
    <div className="p-2">
      {renderRow("ID", experience.id)}
      {renderRow("Name", experience.name)}
      {renderRow("Instructor", experience.instructor_name)}
      {renderRow("Time Slot", experience.time_slot)}
      {renderRow("Completed", experience.is_completed ? "Yes" : "No")}
    </div>
  );
};
