import CustomTable, { TD } from "../../components/odinsonTable";
import { DATE_FORMAT, TIME_FORMAT } from "../../constants/Constants";
import { Tag, Tooltip } from "antd";
import {
  arrayCheck,
  getBookingStatusColor,
  getCurrencySymbol,
} from "../../utils/Utils";

import ExperienceDetails from "../../orders/ListOrders/ExperienceDetails";
import { FC } from "react";
import ImportantLinks from "../../orders/ListOrders/ImportantLinks";
import OdinsonNumberFormat from "../../components/numberFormat";
import dayjs from "dayjs";
import { startCase } from "lodash";

const FILTER_KEYS = [
  "id",
  "is_active",
  "is_deleted",
  "updated",
  "currency",
  "mode",
  "booking_id",
  "contact_name",
  "contact_mobile",
  "contact_country_code",
  "notification",
  "extras",
  "status",
  "experience_time_slot_id",
  "notifications",
  "searchable_text",
  "rating",
  "can_book_again",
  "amount",
  "payable_amount",
  "remaining_amount",
  "can_calendar",
  "package",
  "user",
  "time_remaining",
  "upcoming_trips",
  "promo_code",
  "can_rate_booking",
  "payment_link",
  "is_booking_rated",
  "referee_booking_id",
  "is_calendared",
  "is_experience_rated",
  "offer_code",
];

const ADD_KEYS = [
  {
    key: "Experience",
    position: 1,
  },
  {
    key: "Important Links",
    position: 2,
  },
  {
    key: "Status",
    position: 3,
  },
];

const VendorBookingsTable: FC<{ data: Array<any>; vendorView?: boolean }> = ({
  data,
}) => {
  return (
    <CustomTable addKeys={ADD_KEYS} filterKeys={FILTER_KEYS} data={data}>
      {arrayCheck(data).map((item) => (
        <tr key={item.id}>
          <TD>{dayjs.unix(item.created).format(TIME_FORMAT)}</TD>
          <TD>
            {item.package.name}
            &nbsp;
            <ExperienceDetails experience={item.package} />
          </TD>
          <TD>
            <ImportantLinks
              isVendor
              id={item.booking_id}
              title={item.package.name}
              paymentLink={item.payment_link}
              url={item.package.url}
              experienceId={item.package.id}
            />
          </TD>
          <TD>
            <Tag color={getBookingStatusColor(item.status)}>{item.status}</Tag>
          </TD>
          <TD>{item.display_booking_id}</TD>
          <TD>{item.pax} person</TD>

          <TD>{dayjs.unix(item.start_date).format(DATE_FORMAT)}</TD>
          <TD>{dayjs.unix(item.end_date).format(DATE_FORMAT)}</TD>
          <TD>
            {item.start_time
              ? dayjs.unix(item.start_time).format(TIME_FORMAT)
              : "--"}
          </TD>
          <TD>
            {item.end_time
              ? dayjs.unix(item.end_time).format(TIME_FORMAT)
              : "--"}
          </TD>

          <TD>
            {item.accommodation_details && (
              <Tooltip
                color="cyan"
                title={
                  <div className="text-xs p-2">
                    {Object.keys(item.accommodation_details)
                      .filter((k: string) => k !== "price")
                      .map((key) => (
                        <p key={key}>
                          <span className="font-extralight">
                            {startCase(key)}: &nbsp;
                          </span>
                          {key === "best_deal" ||
                          key === "is_private" ||
                          key === "is_active"
                            ? key
                              ? "Yes"
                              : "No"
                            : item.accommodation_details[key] ?? "--"}
                        </p>
                      ))}
                  </div>
                }
              >
                <Tag color="cyan">View Detail</Tag>
              </Tooltip>
            )}
          </TD>
          <TD>
            {item.subscription_details && (
              <Tooltip
                color="cyan"
                title={
                  <div className="text-xs p-2">
                    {Object.keys(item.subscription_details)
                      .filter(
                        (k: string) =>
                          k !== "discount_price" && k !== "discount_percent"
                      )
                      .map((key) => (
                        <p key={key}>
                          <span className="font-extralight">
                            {startCase(key)}: &nbsp;
                          </span>
                          {key === "best_deal"
                            ? key
                              ? "Yes"
                              : "No"
                            : item.subscription_details[key]}
                        </p>
                      ))}
                  </div>
                }
              >
                <Tag color="cyan">View Detail</Tag>
              </Tooltip>
            )}
          </TD>
          <TD>
            <Tag color={item.is_vendor_payout_complete ? "green" : "red"}>
              {item.is_vendor_payout_complete ? "Yes" : "No"}
            </Tag>
          </TD>
          <TD>
            {item.vendor_payout_amount ? (
              <OdinsonNumberFormat
                currency={getCurrencySymbol(item.currency)}
                value={item.vendor_payout_amount}
              />
            ) : (
              "--"
            )}
          </TD>
          <TD>{item.vendor_payout_id ?? "NA"}</TD>
        </tr>
      ))}
    </CustomTable>
  );
};

export default VendorBookingsTable;
