import { message } from "antd";
import {
  ComponentType,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { User } from "../entities/user";
import Login, { OnLogin } from "../login/Login";
import { get } from "./apiService";
import { authorise, getToken, unauthorise } from "./authService";

const AuthContext = createContext({});

export interface AuthProps {
  isAuthenticated: boolean;
  logout: () => void;
  login: OnLogin;
  user: User | undefined;
}

const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();

  const logout = (): void => {
    setIsAuthenticated(false);
    unauthorise();
  };

  const login: OnLogin = (token) => {
    authorise(token);
    setIsAuthenticated(true);
  };

  // TODO This shold not be here
  const getUser = useCallback(async () => {
    try {
      const { data } = await get(`v1/users/me/`);
      setUser(data);
    } catch (err) {
      message.error("Something went wrong");
    }
  }, []);

  const authContext: AuthProps = {
    isAuthenticated,
    logout,
    login,
    user: isAuthenticated && user ? user : undefined,
  };

  useLayoutEffect(() => {
    const token = getToken();
    if (token) {
      authorise(token);
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) getUser();
  }, [isAuthenticated, getUser]);

  if (!isAuthenticated) return <Login login={login} />;

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export function useAuth(): AuthProps {
  return useContext(AuthContext) as AuthProps;
}

export const withAuth = <P extends object>(
  WrapperComponent: ComponentType<P>
): FC<P> => {
  const AuthHoc = (props: any) => {
    const authData = useContext(AuthContext);
    return <WrapperComponent auth={authData as AuthProps} {...props} />;
  };

  return AuthHoc;
};

export default AuthProvider;
