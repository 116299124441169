import ActionHeader from "../../components/ActionHeader";
import CustomPageHeader from "../../components/pageHeader/PageHeader";
import CustomPagination from "../../components/pagination";
import ErrorCard from "../../components/ErrorCard";
import Loader from "../../loader/Loader";
import OrderSearchAndFilters from "./OrderSearchAndFilters";
import OrderTable from "./OrderTable";
import useGetList from "../../services/hooks/useGetList";

const ListOrders = () => {
  const { state, page, loader, error } = useGetList("v1/orders/");

  if (error) return <ErrorCard />;

  const { total_count, data } = state;
  return (
    <>
      <CustomPageHeader
        title="List orders"
        subTitle="All order listing for BWT"
      />
      <ActionHeader
        page={page}
        totalCount={total_count}
        actionLink="/add-order"
        actionText="Add Order"
      />
      <OrderSearchAndFilters />
      {loader ? <Loader /> : <OrderTable data={data} />}
      <CustomPagination
        total={total_count}
        showQuickJumper
        showSizeChanger
        page={page}
      />
    </>
  );
};

export default ListOrders;
