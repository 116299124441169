import { Button, Input } from "antd";
import { useCallback, useEffect, useState } from "react";

import CustomPageHeader from "../../components/pageHeader/PageHeader";
import Instructions from "./instructions";
import ListCommunicationTemplate from "../components/listCommunicationTemplate";
import OdinsonEmailEditor from "../../components/emailEditor";
import UploadFile from "../../components/uploadFile";
import useSendBulkEmail from "../services/useSendBulkEmail";
import useSetTemplate from "../services/useSetTemplate";

const initialState = {
  subject: "",
  body: "",
  csv_file: "",
  channel: "email",
};

const SendEmail = () => {
  const [state, setState] = useState(initialState);
  const { broadCastEmail, loader } = useSendBulkEmail(state);
  const { onTemplateSelect, selectedTemplate } = useSetTemplate();
  const setFormData = useCallback(
    (name: string, value: string | number) =>
      setState((state) => ({ ...state, [name]: value })),
    []
  );
  const setFile = (info: any) => {
    const { onSuccess } = info;
    setState({
      ...state,
      csv_file: info.file,
    });
    onSuccess("Ok");
  };

  useEffect(() => {
    if (selectedTemplate) {
      setState((s) => ({
        ...s,
        channel: selectedTemplate.communication_type,
        subject: selectedTemplate.subject,
        body: JSON.parse(selectedTemplate.body).html,
      }));
    }
  }, [selectedTemplate]);
  const onSave = (body: string) => setFormData("body", JSON.parse(body).html);
  const buttonDisabledState = loader;
  return (
    <div>
      <CustomPageHeader
        title="Send bulk email"
        subTitle="Upload CSV with Name, Email and broadcast message"
      />
      <div className="my-4">
        <Instructions />
      </div>

      <ListCommunicationTemplate handleChange={onTemplateSelect} />

      <div>
        <p>Template name</p>
        <Input
          value={state.subject}
          onChange={(e) => setFormData("subject", e.target.value)}
          placeholder="Subject"
        />
        <div className="my-3" />
        <p>Subject</p>
        <Input
          value={state.subject}
          onChange={(e) => setFormData("subject", e.target.value)}
          placeholder="Subject"
        />
        <div className="my-3" />
        <UploadFile accept={".csv"} multiple={false} onChange={setFile} />
        {selectedTemplate?.body && (
          <OdinsonEmailEditor
            showSaveButton={false}
            load={selectedTemplate?.body}
            onSave={onSave}
          />
        )}
        <div className="my-3" />
        <Button
          className="mt-3"
          onClick={broadCastEmail}
          disabled={buttonDisabledState}
          loading={loader}
          type="primary"
          children="Send Bulk Email"
        />
      </div>
    </div>
  );
};
export default SendEmail;
