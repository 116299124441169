import { useCallback, useState } from "react";
import { CampaignType } from "../components/types";

type TemplateSelectReturnType = {
  onTemplateSelect: (id: number, campaigns: CampaignType[]) => void;
  selectedTemplate?: CampaignType;
};

const useTemplateSelect = (): TemplateSelectReturnType => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const onTemplateSelect = useCallback((id, campaigns) => {
    const selected = campaigns.find((it: CampaignType) => it.id === id);
    setSelectedTemplate(selected);
  }, []);

  return {
    onTemplateSelect,
    selectedTemplate,
  };
};
export default useTemplateSelect;
