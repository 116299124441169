import { CommunicationType } from "../types";
import useGetList from "../../services/hooks/useGetList";

const useGetTemplate = (type: CommunicationType) => {
  const { state, loader, error } = useGetList("v1/campaigns/", {
    customLimit: 50,
  });
  return {
    list: state,
    loader,
    error,
  };
};
export default useGetTemplate;
